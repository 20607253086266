import { useEffect, useState } from 'react';

import { 
    Container,
    Box,
    Grid,
    CssBaseline,
    Typography,
    Card,
    CardContent,
    CardMedia,
    Button,
    CardActionArea,
    CardActions,
    Link
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

//importar archivos
import Imagen from "../../archivos/3030856.png"
import ImgHistorial from '../../archivos/historial.png'
import ImgReto from "../../archivos/RetoMabe.png"
//importar componentes
import Navbar from "../Navbar"

//importar helpers
import useAuth from '../../hooks/useAuth';
import  secureLocalStorage  from  "react-secure-storage";
import axios from 'axios';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });


const theme = createTheme({
  palette: {
    redcolor: createColor('#ba000d')
  }
});
const client = axios.create({
  baseURL: "https://appsexpedientemedico.azurewebsites.net/api"
});


export default function Modulos() {

  const { validarToken, setVentana } = useAuth()
  const [historialClinico, setHistorialClinico] = useState(false);

  useEffect(() => {
    validarToken()
    setVentana('NA')
    confirmarHC();
    // eslint-disable-next-line
  }, [])

  const confirmarHC = async() => {
    try{
        const config = {
            headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}`}
        };
        const response = await client.get(`/HC/confirmarHC`,config)
        setHistorialClinico(response.data.response)
    }catch(e){
        console.log(e.message)
    }
  }

  const navigate = useNavigate();

  const modulos = [ 
    {
      id: 1,
      texto: "Cita médica",
      modulo: 'cita',
      imagen: Imagen
    },
    {
      id: 2,
      texto: "Historial de citas",
      modulo: 'historialcitas',
      imagen: ImgHistorial
    }
  ]

  const navegar = (modulo) => {
    
    navigate(`/${modulo}`)
  }

  return (
    <ThemeProvider theme={theme}>
      <Navbar/>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <Typography component="h1" variant="h4">
            Módulos
          </Typography>
          { !historialClinico || historialClinico==="No hay examen"?<Button  style={{}} onClick={() => navegar('historialclinico')}>Realizar Historial Clinico</Button>:null}
          {historialClinico === "En espera de respuesta del Dr"?"Historial en espera de respuesta de Salud Ocupacional":
          historialClinico === "Falta firma"?<Button style={{fontWeight:"bold",padding:"5px 0 0 5px", margin:"10px 0 10px 0 ",borderBottom:"solid"}} onClick={() => {navegar('historialClinicoFirma')}}>Confirmar Historial Médico</Button>:
          historialClinico === "Examen terminado"?"Historial Médico terminado":
          null}
          <Box component="form" Validate sx={{ mt: 3 }}>
            
            <Grid container spacing={2}>
            {/* <Grid md={12} >
                            <Link style={{float:"right"}} rel='noopener' target='blank' href="https://mabe.sharepoint.com/sites/rh/RMFIT/NM/Paginas/F2Menu.aspx" underline="none">
                                {'Reto Mabe'}
                            </Link>
                        </Grid> */}
              {
                modulos.map(e => (
                  <Grid key={e['id']} item lg={4} sm={4} xs={12}>
                  <Card key={e['id']} sx={{ maxWidth: 200, maxHeight: 320 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    image={e['imagen']}
                    alt="green iguana"
                    onClick={() => navegar(e['modulo'])}
                  />
                  <CardContent style={{textAlign:"center"}}>
                    <Typography gutterBottom variant="h6" component="div">
                      {e['texto']}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions style={{justifyContent:"center"}}>
                  <Button onClick={() => navegar(e['modulo'])} size="small" color="primary">
                    Ingresar
                  </Button>
                </CardActions>
              </Card>    
              </Grid>
                ))
                   
              }     
              {/* -----------LINK RETO MABE ------------------- */}
              <Grid key={"reto"} item lg={4} sm={4} xs={12}>
                  <Card key={"reto"} sx={{ maxWidth: 200, maxHeight: 320 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    image={ImgReto}
                    alt="green iguana"
                    onClick={() => window.open('https://mabe.sharepoint.com/sites/rh/RMFIT/NM/Paginas/F2Menu.aspx', '_blank')}
                  />
                  <CardContent style={{textAlign:"center"}}>
                    <Typography gutterBottom variant="h6" component="div">
                      {'Reto Mabe'}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions style={{justifyContent:"center"}}>
                  <Button onClick={() => window.open('https://mabe.sharepoint.com/sites/rh/RMFIT/NM/Paginas/F2Menu.aspx', '_blank')} size="small" color="primary">
                    Ingresar
                  </Button>
                </CardActions>
              </Card>    
              </Grid>
              {/* -----------</LINK RETO MABE> ------------------- */}
            </Grid>
            
            
          </Box>
        </Box>

      </Container>
    </ThemeProvider>
  );
}