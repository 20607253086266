//importar librerias
import { useState, useEffect } from 'react';
import { Table, Button, Tooltip, CssBaseline, Box, Typography, Container, Grid } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TablePagination from '@mui/material/TablePagination';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router-dom';

import { HistorialClinicoProvider } from '../../../context/HistorialClinicoProvider';
//Hooks
import useAuth from '../../../hooks/useAuth';

//Component
import Navbar from '../Navbar'
import TablaListaAnios from './ListaAniosHistorial';

import { createTheme, ThemeProvider } from '@mui/material/styles';
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
    palette: {
        redcolor: createColor('#ba000d')
    }
});
const AniosListaHistorial = () => {
    const history = useNavigate()
    const { setVentana } = useAuth()

    useEffect(() => {
        setVentana('/lista')
        if(localStorage.getItem('AvancePaciente')!=null)
        {
            localStorage.removeItem('AvancePaciente')
        }
        // eslint-disable-next-line
    }, [])


    const handleSubmit = e => {
        e.preventDefault();
    }
    return (
        <HistorialClinicoProvider>
            <Navbar />
            <ThemeProvider theme={theme}>

                <Container component="main">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h4">
                            Historial de {(JSON.parse(localStorage.getItem('guidBusquedaExamenes'))).userNombre+" "+
                                        (JSON.parse(localStorage.getItem('guidBusquedaExamenes'))).userPApellido+" "+
                                        (JSON.parse(localStorage.getItem('guidBusquedaExamenes'))).userSApellido}
                        </Typography>
                    </Box>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>

                        <Grid container spacing={2}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <TablaListaAnios/>
                            </Grid>
                        </Grid>
                    </Box>

                </Container>
            </ThemeProvider>
        </HistorialClinicoProvider>
    )
}

export default AniosListaHistorial