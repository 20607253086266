import * as React from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  Alert
} from '@mui/material/';
import validator from 'validator'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import useContrasena from '../../hooks/useContrasena';

const theme = createTheme();

export default function Login() {

  const { setError, setBand, datos, setDatos, handleChange, timer, onClickReset, enviarCodigoApi, validPassword, setValidPassword,actualizarContrasena } = useContrasena()

  const { correo, confirmarCorreo, codigo, contrasena, confirmarContrasena, sendCodigo } = datos;

  const { bandPass, textoPass } = validPassword


  const enviarCodigo = () => {
    if (correo !== '' && confirmarCorreo !== '') {
      if (correo === confirmarCorreo) {
        setDatos({ ...datos, codigo: '1' })
        onClickReset()
        enviarCodigoApi()
        setBand(Math.random())
      } else {
        setError({ band: true, texto: 'Los correos deben ser iguales' });
      }
    } else {
      setError({ band: true, texto: 'Debes ingresar el correo' });
    }
  };

  const actualizar = () => {
    if (datos.contrasena !== datos.confirmarContrasena) {
      setValidPassword({ bandPass: true, textoPass: 'Las contraseñas no coinciden' })
    } else {
      if (datos.contrasena === '' || datos.confirmarContrasena === '') {
        setValidPassword({ bandPass: true, textoPass: 'Las contraseñas no deben de estar vacias' })
      } else {
        if (validator.isStrongPassword(contrasena, {
          minLength: 8, minLowercase: 1,
          minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
          if(datos.sendCodigo!==''){
          actualizarContrasena()
          }else{
            setValidPassword({ bandPass: true, textoPass: 'El código no debe de estar vacio' })
          }
        } else {
          setError({ band: true, texto: 'La contraseña debe contar con 8 o más caracteres, incluyendo números, mayúsculas, minúsculas y caracteres especiales ($#%)' })
        }
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recuperar contraseña
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="correo"
              label="Correo"
              name="correo"
              autoComplete="correo"
              autoFocus
              value={correo}
              onChange={handleChange}
              disabled={codigo === '1' ? true : false}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="correo"
              label="Confirmar correo"
              name="confirmarCorreo"
              autoComplete="correo"
              autoFocus
              value={confirmarCorreo}
              onChange={handleChange}
              disabled={codigo === '1' ? true : false}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{ backgroundColor: "#008aa7" }}
              onClick={enviarCodigo}
              disabled={timer !== '00:00:00' && codigo === '1' ? true : false}
            >
              {codigo === '0' ? "Obtener código" : "Reenviar código"}
            </Button>
            {
              codigo === '1' ? (<p>Volver a intentar en {timer}</p>) : ""
            }

          </Box>
          {
            codigo === '1' ? (<Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="sendCodigo"
                label="Código"
                name="sendCodigo"
                autoComplete="sendCodigo"
                autoFocus
                value={sendCodigo}
                onChange={handleChange}
              />
              <TextField
                required
                margin="normal"
                fullWidth
                id="contrasena"
                label="Nueva contraseña"
                name="contrasena"
                autoComplete="current-password"
                type="contrasena"
                autoFocus
                value={contrasena}
                onChange={handleChange}
                error={bandPass}
                helperText={textoPass}
              />
              <TextField
                required
                margin="normal"
                fullWidth
                id="confirmarContrasena"
                label="Confirmar contraseña"
                name="confirmarContrasena"
                autoComplete="current-password"
                type="confirmarContrasena"
                autoFocus
                value={confirmarContrasena}
                onChange={handleChange}
                error={bandPass}
                helperText={textoPass}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{ backgroundColor: "#008aa7" }}
                onClick={actualizar}

              >
                Actualizar
              </Button>


            </Box>) : null
          }
        </Box>
      </Container>
    </ThemeProvider>
  );
}