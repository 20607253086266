//importar librerias
import * as React from 'react';
import { useEffect } from 'react'
import {
    Box,
    Typography,
    Button
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
//importar funciones
import useAuth from '../../../hooks/useAuth';
import useHistorialClinico from '../../../hooks/useHistorialClinico';
import { useState } from 'react';

//Stepper
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';

//Preguntas
import Cabecera from './Cabecera';
import FichaIdentificacion from './FichaIdentificacionM';
import HeredoFamiliares from './HeredofamiliaresM';
import NoPatologicos from './NoPatologicosM';
import Patologicos from './PatologicosM';
import GinecoObstetricos from './GinecoObstetricos';
import AntecedentesLaborales from './Laborales';
import ExploracionFisica from './Exploracion';
import FirmaMedico from './FirmaMedico';

const steps = ['Datos basicos',
    'Ficha de Identificación',
    'Antecedentes Heredofamiliares',
    'Antecedentes personales no patológicos',
    'Antecedentes personales patológicos',
    'Antecedentes gineco-obstétricos / andrológicos',
    'Antecedentes laborales',
    'Exploración física',
    'Finalizar'];

export default function StepperClinicoM() {
    const { validarToken, setVentana } = useAuth()
    const { guardarHistorialMedico, datosMedico } = useHistorialClinico();

    useEffect(() => {
        validarToken()
        // eslint-disable-next-line
    }, [])

    //Stepper
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 10;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        // if (isStepSkipped(activeStep)) {
        //     newSkipped = new Set(newSkipped.values());
        //     newSkipped.delete(activeStep);
        // }


        if (activeStep === steps.length - 1) {
            if (datosMedico.historialclinico.hcFirmaMedico === "Si")
            {   guardarHistorialMedico();
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setSkipped(newSkipped);
            }
            else
                handleClick();
            //guardarHistorialMedico();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }
    };



    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };



    const Componentes = () => {

        switch (activeStep + 1) {
            case 1:
                return <Cabecera />
                break;
            case 2:
                return <FichaIdentificacion />
                break;
            case 3:
                return <HeredoFamiliares />
                break;
            case 4:
                return <NoPatologicos />
                break;
            case 5:
                return <Patologicos />
                break
            case 6:
                return <GinecoObstetricos />
                break;
            case 7:
                return <AntecedentesLaborales />
                break;
            case 8:
                return <ExploracionFisica />
                break;
            case 9:
                return <FirmaMedico />
        }

    }

    //Snackbar
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };



    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1, pt: 10, textAlign: "center" }}>
                        Has terminado tu historial médico, serás redirigido en un momento.
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* <Button onClick={handleReset}>Reset</Button> */}
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1, mt: 6 }}>
                        {Componentes()}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mb: 6 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Atras
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}

                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                    Necesitas confirmar para finalizar.
                </Alert>
            </Snackbar>
        </Box>

    )

}