import { forwardRef, useEffect, useState } from 'react'

import {
    Radio,
    RadioGroup,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    InputLabel,
    Input,
    TextField,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import useHistorialClinico from '../../../hooks/useHistorialClinico';

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const FichaIdentificacion = () => {

    const { datos, dataUser, handleChangeClinico} = useHistorialClinico()
    const {
        pLugarNacimiento,
        pEscolaridad,
        pEstadoCivil,
        pHijos,
        pTelEmergencia
    } = datos

    return (
        <>
        {dataUser!=undefined?
        <Grid container spacing={2} p={1} style={{backgroundColor:"#FFF"}}>
            
            <Grid item lg={8} sm={8} xs={12}>
                <TextField
                    autoComplete="given-name"
                    name="medicoNombre"
                    fullWidth
                    id="medicoNombre"
                    defaultValue={dataUser.userNombre+" "+dataUser.userPApellido+" "+dataUser.userSApellido+""}
                    label="Nombre (s)"
                    autoFocus
                    variant="standard"
                    disabled
                />
            </Grid>
            <Grid item lg={4} sm={4} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center" }}>
                    <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px" }}>Género</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue={dataUser.userGenderId}
                        disabled
                    >
                        <FormControlLabel value={1} control={<Radio disabled/>} label="Masculino" />
                        <FormControlLabel value={2} control={<Radio disabled/>} label="Femenino" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item lg={4} sm={4} xs={12} style={{display:"flex"}}>
                <TextField
                    style={{ width: "80%" }}
                    type='number'
                    id="medicoPApellido"
                    label="Edad"
                    name="medicoPApellido"
                    defaultValue={dataUser.userAge}
                    variant="standard"
                    disabled
                    autoComplete="family-name"
                />
                <Typography style={{width:"20%"}} variant="body1">
                    Años
                </Typography>
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    id="fecha"
                    name="fecha"
                    label="Fecha de nacimiento"
                    type="date"
                    variant="standard"
                    defaultValue={dataUser.userNacimiento.slice(0, 10)}
                    fullWidth
                    disabled
                    // onChange={handleChangeDatos}
                    InputLabelProps={{
                        shrink: true,
                    }
                    } />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    fullWidth
                    id="pLugarNacimiento"
                    label="Lugar de nacimiento"
                    name="principal,pLugarNacimiento"
                    defaultValue={datos.pLugarNacimiento}
                    onChange={(e) => handleChangeClinico(e)}
                    variant="standard"
                    autoComplete="family-name"
                />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    fullWidth
                    id="pEscolaridad"
                    label="Escolaridad"
                    name="principal,pEscolaridad"
                    defaultValue={datos.pEscolaridad}
                    onChange={(e) => handleChangeClinico(e)}
                    variant="standard"
                    // value={pTelEmergencia}
                    autoComplete="family-name"
                />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    fullWidth
                    id="pEstadoCivil"
                    label="Estado Civil"
                    name="principal,pEstadoCivil"
                    defaultValue={datos.pEstadoCivil}
                    onChange={(e) => handleChangeClinico(e)}
                    variant="standard"
                    // value={pTelEmergencia}
                    autoComplete="family-name"
                />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    fullWidth
                    type='number'
                    id="pHijos"
                    label="Hijos"
                    name="principal,pHijos"
                    defaultValue={datos.pHijos}
                    onChange={(e) => handleChangeClinico(e)}
                    variant="standard"
                    // value={pTelEmergencia}
                    autoComplete="family-name"
                />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
                <TextField
                    fullWidth
                    id="Domicilio"
                    label="Domicilio"
                    name="Domicilio"
                    variant="standard"
                    value={dataUser.userStreet+" "+dataUser.userNext+" "+dataUser.colonia}
                    disabled
                    autoComplete="family-name"
                />
            </Grid>
            <Grid item lg={4} sm={12} xs={12}>
                <InputLabel htmlFor="formatted-text-mask-input">Teléfono</InputLabel>
                <Input
                    // value={userPhone}
                    required
                    fullWidth
                    name="userPhone"
                    id="userPhone"
                    inputComponent={TextMaskCustom}
                    value={dataUser.userPhone}
                    disabled
                    // onChange={handleChangeUsuario}
                />
            </Grid>
            <Grid item lg={8} sm={12} xs={12}>
                <TextField
                    fullWidth
                    id="pTelEmergencia"
                    label="En caso de emergencia contactar"
                    name="principal,pTelEmergencia"
                    defaultValue={datos.pTelEmergencia}
                    onChange={(e) => handleChangeClinico(e)}
                    variant="standard"
                    // value={pTelEmergencia}
                    autoComplete="family-name"
                />
                <span id="pTelEmergenciaS" style={{ fontSize: "0.6em", fontStyle: "italic" }}> Numero de teléfono, nombre y parentesco</span>
            </Grid>
        </Grid>
        :null}
       </> 
    )
}

export default FichaIdentificacion