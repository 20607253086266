import axios from "axios";
import { createContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import useAuth from "../hooks/useAuth";
import secureLocalStorage from "react-secure-storage";
const HistorialClinicoContext = createContext()

const client = axios.create({
  baseURL: "https://appsexpedientemedico.azurewebsites.net/api"
});
const HistorialClinicoProvider = ({ children }) => {
  const [datos, setDatos] = useState({
    userG: "",
    pLugarNacimiento: "",
    pEscolaridad: "",
    pEstadoCivil: "",
    pHijos: 0,
    pTelEmergencia: "",
    antHerFam: {
      antherId: 0,
      antherGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      antherHcFk: 0,
      antherDiabetes: 0,
      antherDiabetesQuien: "",
      antherHipertencion: 0,
      antherHipertencionQuien: "",
      antherCancer: 0,
      antherCancerQuien: "",
      antherEnfermedadCorazon: 0,
      antherEnfermedadCorazonQuien: "",
      antherEnfermedadRinon: 0,
      antherEnfermedadRinonQuien: "",
      antherAsmaP: 0,
      antherAsmaQuien: "",
      antherNeurodegenerativas: 0,
      antherNeurodegenerativasQuien: "",
      antherAutoinmunes: 0,
      antherAutoinmunesQuien: "",
      antherDiscAuditvaVisual: 0,
      antherDiscAuditvaVisualQuien: "",
      antherConvulsiones: 0,
      antherConvulsionesQuien: ""
    },
    antNoPatologicos: {
      antnopaId: 0,
      antnopaGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      antnopaHcFk: 0,
      antnopaCasaTipo: "Propia",
      antnopaCasaMaterial: "",
      antnopaCuartos: 0,
      antnopaPersonasCuarto: 0,
      antnopaMascotas: "",
      antnopaServicios: "",
      antnopaHigienePersonal: "Diario",
      antnopaAceoBucal: "3 x dia",
      antnopaVacunacion: "",
      antnopaEjercicio: "",
      antnopaTipoSangre: "",
      antnopaComentariosAntecedentesPersonales: "",//Este se queda vacio, debe llenarlo el DR en su parte
      antnopaIntencidadEjercicio: "Leve",
      antnopaDuracionActividad: "",
      antnopaFrutasVerduras: "5-7semana",
      antnopaAzucaresRefinados: "5-7semana",
      antnopa4a6vasosAgua: "5-7semana",
      antnopaGrasasSaturadas: "5-7semana",
      antnopaCarnesRojas: "5-7semana",
      antnopaAuxiliarEscucharCaminar: "",
      antnopaAmikacinaGentamicina: "",
      antnopaSonidoFuerte: ""
    },
    //Todos los que terminan en 'Com' se mandan vacios, estos los llena el DR
    antPatologicos: {
      antpaId: 0,
      antpaGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      antpaHcFk: 0,
      antpaAlergiasPa: "No",
      antpaAlergiasPaCom: "",
      antpaAsmaPa: "No",
      antpaAsmaPaCom: "",
      antpaCancerPa: "No",
      antpaCancerPaCom: "",
      antpaCirugiasPa: "No",
      antpaCirugiasPaCom: "",
      antpaConvulsionesPa: "No",
      antpaConvulsionesPaCom: "",
      antpaDesmayosPa: "No",
      antpaDesmayosPaCom: "",
      antpaDiabetesPa: "No",
      antpaDiabetesPaCom: "",
      antpaDolorEspaldaCuelloPa: "No",
      antpaDolorEspaldaCuelloPaCom: "",
      antpaLimitacionOralEscritaPa: "No",
      antpaLimitacionOralEscritaPaCom: "",
      antpaDisminucionVisualPa: "No",
      antpaDisminucionVisualPaCom: "",
      antpaDisminucionAudicionPa: "No",
      antpaDisminucionAudicionPaCom: "",
      antpaEnfPielPa: "No",
      antpaEnfPielPaCom: "",
      antpaEnfPulmonesPa: "No",
      antpaEnfPulmonesPaCom: "",
      antpaEnfRinonesPa: "No",
      antpaEnfRinonesPaCom: "",
      antpaEnfCorazonPa: "No",
      antpaEnfCorazonPaCom: "",
      antpaEnfHigadoPa: "No",
      antpaEnfHigadoPaCom: "",
      antpaFracturasEsguincesAmputacionPa: "No",
      antpaFracturasEsguincesAmputacionPaCom: "",
      antpaFumaPa: "No",
      antpaFumaPaCom: "",
      antpaHipertensionPa: "No",
      antpaHipertensionPaCom: "",
      antpaBebidasAlcoholicasPa: "No",
      antpaBebidasAlcoholicasPaCom: "",
      antpaVaricesPa: "No",
      antpaVaricesPaCom: "",
      antpaSarampionPa: "No",
      antpaSarampionPaCom: "",
      antpaVaricelaPa: "No",
      antpaVaricelaPaCom: "",
      antpaRubeolaPa: "No",
      antpaRubeolaPaCom: "",
      antpaHerpesPa: "No",
      antpaHerpesPaCom: "",
      antpaParotiditisPa: "No",
      antpaParotiditisPaCom: "",
      antpaHepatitisPa: "No",
      antpaHepatitisPaCom: "",
      antpaTransfusionesPa: "No",
      antpaTransfusionesPaCom: "",
      antpaGastritisPa: "No",
      antpaGastritisPaCom: "",
      antpaColitisPa: "No",
      antpaColitisPaCom: "",
      antpaHerniasPa: "No",
      antpaHerniasPaCom: "",
      antpaAutoinmunesPa: "No",
      antpaAutoinmunesPaCom: "",
      antpaLimitacionMovilidadPa: "No",
      antpaLimitacionMovilidadPaCom: "",
      antpaProblemasNacimientoPa: "No",
      antpaProblemasNacimientoPaCom: "",
      antpaConsumeMedicamentoPa: "No",
      antpaConsumeMedicamentoPaCom: "",
      antpaDepresionAnsiedadPa: "No",
      antpaDepresionAnsiedadPaCom: "",
      antpaTerapiaPa: "No",
      antpaTerapiaPaCom: "",
      antpaOtrasPa: "No",
      antpaOtrasPaCom: "",
      antpaSeniasParticularesPa: "No",
      antpaSeniasParticularesPaCom: ""
    }
  })

  const [datosMedico, setDatosMedico] = useState({
    userG: "3fa85f64-5717-4562-b3fc-2c963f66afa6", //guis del usuariopaciente
    historialclinico: {
      hcId: 0,//dejar por default
      hcGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",//dejar por default
      hcYear: 0,//dejar por default la api busca el anio actual
      hcStatus: 0,//dejar por default
      hcTipo: "Ingreso",
      hcAgenteAmbiental: "",
      hcUsuarioFk: 0,//dejar por default
      hcPacienteLugarNacimiento: "",//dejar por default
      hcPacienteEscolaridad: "",//dejar por default
      hcPacienteEstadoCivil: "",//dejar por default
      hcPacienteHijos: 0,//dejar por default
      hcPacienteTelEmergencia: "",//dejar por default
      hcAniosGo: "",
      hcRitmoGo: "",
      hcFumGo: "",
      hcDismenorreaGo: "",
      hcGGo: "",
      hcPGo: "",
      hcAGo: "",
      hcCGo: "",
      hcFupocGo: "",
      hcMpfGo: "",
      hcDocFechaGo: "",
      hcDocResultGo: "",
      hcAntigenoFechaGo: "",
      hcAntigenoResultGo: "",
      hcDocmaGo: "",
      hcFirmaEvaluado: "",//falta preguntar cuando firma el paciente
      hcFirmaMedico: "",//se debe firmar al ejecutar este endpoin base64
      hcMedicoFk: 0,//dejar por default
      hcDcreate: "2023-07-20T20:23:37.788Z",//dejar por default
      hcPuesto: ""
    },
    comentariosPatologicos: {
      antpaId: 0,//dejar por default
      antpaGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",//dejar por default
      antpaHcFk: 0,//dejar por default
      antpaAlergiasPa: "",
      antpaAlergiasPaCom: "",
      antpaAsmaPa: "",
      antpaAsmaPaCom: "",
      antpaCancerPa: "",
      antpaCancerPaCom: "",
      antpaCirugiasPa: "",
      antpaCirugiasPaCom: "",
      antpaConvulsionesPa: "",
      antpaConvulsionesPaCom: "",
      antpaDesmayosPa: "",
      antpaDesmayosPaCom: "",
      antpaDiabetesPa: "",
      antpaDiabetesPaCom: "",
      antpaDolorEspaldaCuelloPa: "",
      antpaDolorEspaldaCuelloPaCom: "",
      antpaLimitacionOralEscritaPa: "",
      antpaLimitacionOralEscritaPaCom: "",
      antpaDisminucionVisualPa: "",
      antpaDisminucionVisualPaCom: "",
      antpaDisminucionAudicionPa: "",
      antpaDisminucionAudicionPaCom: "",
      antpaEnfPielPa: "",
      antpaEnfPielPaCom: "",
      antpaEnfPulmonesPa: "",
      antpaEnfPulmonesPaCom: "",
      antpaEnfRinonesPa: "",
      antpaEnfRinonesPaCom: "",
      antpaEnfCorazonPa: "",
      antpaEnfCorazonPaCom: "",
      antpaEnfHigadoPa: "",
      antpaEnfHigadoPaCom: "",
      antpaFracturasEsguincesAmputacionPa: "",
      antpaFracturasEsguincesAmputacionPaCom: "",
      antpaFumaPa: "",
      antpaFumaPaCom: "",
      antpaHipertensionPa: "",
      antpaHipertensionPaCom: "",
      antpaBebidasAlcoholicasPa: "",
      antpaBebidasAlcoholicasPaCom: "",
      antpaVaricesPa: "",
      antpaVaricesPaCom: "",
      antpaSarampionPa: "",
      antpaSarampionPaCom: "",
      antpaVaricelaPa: "",
      antpaVaricelaPaCom: "",
      antpaRubeolaPa: "",
      antpaRubeolaPaCom: "",
      antpaHerpesPa: "",
      antpaHerpesPaCom: "",
      antpaParotiditisPa: "",
      antpaParotiditisPaCom: "",
      antpaHepatitisPa: "",
      antpaHepatitisPaCom: "",
      antpaTransfusionesPa: "",
      antpaTransfusionesPaCom: "",
      antpaGastritisPa: "",
      antpaGastritisPaCom: "",
      antpaColitisPa: "",
      antpaColitisPaCom: "",
      antpaHerniasPa: "",
      antpaHerniasPaCom: "",
      antpaAutoinmunesPa: "",
      antpaAutoinmunesPaCom: "",
      antpaLimitacionMovilidadPa: "",
      antpaLimitacionMovilidadPaCom: "",
      antpaProblemasNacimientoPa: "",
      antpaProblemasNacimientoPaCom: "",
      antpaConsumeMedicamentoPa: "",
      antpaConsumeMedicamentoPaCom: "",
      antpaDepresionAnsiedadPa: "",
      antpaDepresionAnsiedadPaCom: "",
      antpaTerapiaPa: "",
      antpaTerapiaPaCom: "",
      antpaOtrasPa: "",
      antpaOtrasPaCom: "",
      antpaSeniasParticularesPa: "",
      antpaSeniasParticularesPaCom: ""
    },
    antecedentesLaborales: [
      {
        antlId: 0,//dejar por default
        antlGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",//dejar por default
        antlAnio: 0,
        antlUsuarioFk: 0,//dejar por default
        antlAntiguedad: "",
        antlEmpresa: "",
        antlActividad: "",
        antlDuracion: "",
        antlAgentesExposicion: "",
        antlDuracionExposicion: "",
        antlAntecedentesEnfProfesionales: "",
        antlIncapacidades: "",
        antlHcFk: 0
      }
    ],
    exploracionFisica: {
      expFisId: 0,//dejar por default
      expFisGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",//dejar por default
      expFisHcFk: 0,//dejar por default
      expFisPeso: "",
      expFisTalla: "",
      expFisTa: "",
      expFisLpm: "",
      expFisRmp: "",
      expFisTemp: "",
      expFisSato2: "",
      expFisAgudVisSnellenOd: "",
      expFisAgudVisSnellenOi: "",
      expFisAgudVisJeagerOd: "",
      expFisAgudVisJeagerOi: "",
      expFisDiscrimColores: "",
      expFisCampVisOd: "",
      expFisCampVisOi: "",
      expFisIms: "",
      expFisPorGrasa: "",
      expFisPoMusculo: "",
      expFisPorVisceral: "",
      expFisGlucosa: "",
      expFisColesterol: "",
      expFisTrigliceridos: "",
      expFisHabitExterior: "",
      expFisCraneo: "",
      expFisOidos: "",
      expFisOjos: "",
      expFisNariz: "",
      expFisBoca: "",
      expFisFaringe: "",
      expFisCuello: "",
      expFisTorax: "",
      expFisAbdomen: "",
      expFisColumnaVert: "",
      expFisAltMarchaPuntaTalon: "",
      expFisMiembrosSup: "",
      expFisMiembrosInf: "",
      expFisPiel: "",
      expFisArcosMovi: "",
      expFisPruebasVesti: "",
      expFisReflejosOsteo: "",
      expFisTatuajes: "",
      expFisAudiometria: "",
      expFisEspirometria: "",
      expFisRayosX: "",
      expFisEstudiosLab: "",
      expFisEstudiosLabEsp: "",
      expFisDiagPrevInstEmi: "",
      expFisDiagPrevFecha: "",
      expFisDiagPrevDiagnostico: "",
      expFisDiagPrevTipoEst: "",
      expFisDiagPrevDiagEstudio: "",
      expFisDiagPrevFechaEstudio: "",
      expFisDiagPrevComentarios: "",
      expFisIdx: "",
      expFisPlan: "",
      expFisRecomendable: "",
      expFisRecomendableCondi: "",
      expFisNoRecomendable: ""
    },
    comentariosNoPatologicos: ""
  })
  const history = useNavigate();

  //------------------Manejo Datos Paciente------------------------
  const handleChangeClinico = (e) => {
    console.log("changeClinico")
    const newData = { ...datos };
    var name = e.target.name, names, ruta, subruta;
    names = name.split(",")
    ruta = names[0];
    subruta = names[1];

    if (ruta === "principal") {
      newData[subruta] = e.target.value;
    } else {
      newData[ruta][subruta] = e.target.value;
    }
    setDatos(newData)
  }
  const handleChangeServicios = (e, ruta) => {
    console.log("changeServicio")

    const newData = { ...datos };
    newData.antNoPatologicos[ruta] = e;
    setDatos(newData)
  }

  const [firmaPaciente, setFirmaPaciente] = useState({
    firma: "No"
  });

   
  //------------------</Manejo Datos Paciente>------------------------
  //------------------<Manejo Datos Medico>------------------------
  const handleChangeClinicoMed = (e) => {
    const newData = { ...datosMedico };
    var name = e.target.name, names, ruta, subruta;
    names = name.split(",")
    ruta = names[0];
    subruta = names[1];
    if (ruta === "principal") {
      newData[subruta] = e.target.value;
    } else {
      if (ruta === "comentariosNoPatologicos")
        newData.comentariosNoPatologicos = e.target.value;
      else
        newData[ruta][subruta] = e.target.value;
    }
    setDatosMedico(newData)
  }
  const handleChangeFirmaM = (e) =>{
    const newData = { ...datosMedico };
    if(datosMedico.historialclinico.hcFirmaMedico != ""){
      if(datosMedico.historialclinico.hcFirmaMedico === "Si")
        newData.historialclinico.hcFirmaMedico = "No";
      else
        newData.historialclinico.hcFirmaMedico = "Si";
    }else{
      newData.historialclinico.hcFirmaMedico = "Si";
    }
    // console.log(datosMedico.historialclinico.hcFirmaMedico)

  }
  //------------------</Manejo Datos Medico>------------------------

  const [dataUser, setDataUser] = useState();
  const [usuariosListos, setUsuariosListos] = useState();
  const [dataAvance, setDataAvance] = useState();
  //Data basica del usuario actual
  const datosUser = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}` }
      };
      const response = await client.post(`/users/GetMyUser`, ``, config)
      setDataUser(response.data.response)
      console.log(response)
    } catch (e) {
      console.log(e.message)
    }
  }

  const guardarHistorialPaciente = () => {
    console.log("Finalizando provider");
    var arr = datos.antNoPatologicos.antnopaServicios, serv = "", arr2 = datos.antNoPatologicos.antnopaVacunacion, vac = "";
    for (let index = 0; index < arr.length; index++) { serv = serv + "," + arr[index]; }

    for (let index = 0; index < arr2.length; index++) { vac = vac + "," + arr2[index]; }

    serv = serv.slice(1);
    vac = vac.slice(1);

    const newData = { ...datos };
    newData.antNoPatologicos.antnopaServicios = serv;
    newData.antNoPatologicos.antnopaVacunacion = vac;
    newData.userG = dataUser.userGuid;
    setDatos(newData)
    // console.log(dataUser.userGuid)
    // console.log(newData)
    crearHistorialPaciente();
  }

  const guardarFirmaPaciente = async () =>{
    try {
      const config = {
        headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}` }
      };
      const response = await client.post(`HC/firmaPaciente`, firmaPaciente, config)
      history(`/modulos`)
    } catch (e) {
      console.log(e)
    }
  }

    // Función para buscar y actualizar datos vacíos en un objeto
    const actualizarObjeto = (objeto) => {
      for (let clave in objeto) {
        if (objeto[clave] === "") {
          objeto[clave] = "N/A";
        }
      }
    };
    
  const guardarHistorialMedico = () => {
    console.log("Finalizando medico provider");

    const newData = { ...datosMedico };
    newData.antecedentesLaborales = antecedentesLaborales;
    newData.userG = dataAvance.paciente.userGuid;

    if (newData.historialclinico.hcDocFechaGo != "")
      newData.historialclinico.hcDocFechaGo = datosMedico.historialclinico.hcDocFechaGo + "T12:00:00.251Z";
    else
      newData.historialclinico.hcDocFechaGo = "1900-01-01" + "T12:00:00.251Z";

    if (newData.historialclinico.hcAntigenoFechaGo != "")
      newData.historialclinico.hcAntigenoFechaGo = datosMedico.historialclinico.hcAntigenoFechaGo + "T12:00:00.251Z";
    else
      newData.historialclinico.hcAntigenoFechaGo = "1900-01-01" + "T12:00:00.251Z";

    // Actualizar historialclinico
    actualizarObjeto(newData.historialclinico);

    // Actualizar comentariosPatologicos
    actualizarObjeto(newData.comentariosPatologicos);

    // Actualizar exploracionFisica
    actualizarObjeto(newData.exploracionFisica);


    setDatosMedico(newData)
    // console.log(newData)
    crearHistorialMedico(newData);
  }

  //Usuarios que han hecho su examen medico
  const listaUsuariosL = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}` }
      };
      const response = await client.get(`/HC/confirmarHCDR`, config)
      setUsuariosListos(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  //Obtener parte del usuario
  const userExam = async (e) => {
    console.log("Examen")
    try {
      const config = {
        headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}` }
      };
      const data = {
        userGuid: e,//guid del usuario
        year: 0//este de deja asi por default, la api busca el hc del anio actual
      }
      const response = await client.post(`/HC/GetClientPartHC`, data, config)
      setDataAvance(response.data.response)
      localStorage.setItem('AvancePaciente', JSON.stringify(response.data.response))
      history(`/historialclinicomed`)
    } catch (e) {
      console.log(e)
    }
  }

  //Historial años
  const [historialPaciente, setHistorialPaciente] = useState();
  const obtenerHistorialPaciente = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}` }
      };
      const data = {
        userGuid: JSON.parse(localStorage.getItem('guidBusquedaExamenes')).userGuid//Guid del usuario a consultar sus historiales con status 2
      }
      console.log(data)
      const response = await client.post(`/HC/ObtenerlistHistorialesClinicos`, data, config)
      console.log(response.data.response)
      setHistorialPaciente(response.data.response)

    } catch (e) {
      console.log(e)
    }
  }

  const [historialAnio, setHistorialAnio] = useState();
  const obtenerHistorialAnio = async (e) => {
    console.log("historial paciente año")
    console.log(e)
    try {
      const config = {
        headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}` }
      };
      const data = e;
      const response = await client.post(`/HC/ObtenerHistorialClinico`, data, config)
      // console.log(response.data.response)
      // setHistorialAnio(response.data.response)
      localStorage.setItem('HistorialAnio', JSON.stringify(response.data.response))
      history(`/historialclinicoDetalle`)
    } catch (e) {
      console.log(e)
    }
  }

  const [historialFecha, setHistorialFecha] = useState();
  const obtenerHistorialFecha = async () => {
    console.log("historial paciente sin firma")
    try {
      const config = {
        headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}` }
      };
      const data = "";
      const response = await client.post(`/HC/ObtenerHistorialClinicoF`, data, config)
      console.log(response.data.response)
      setHistorialFecha(response.data.response)
      // localStorage.setItem('HistorialAnio', JSON.stringify(response.data.response))
    } catch (e) {
      console.log(e)
    }
  }


  // Crear parte de paciente
  const crearHistorialPaciente = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}` }
      };
      console.log("Registro Historial paciente")
      const newData = { ...datos };
      newData.userG = dataUser.userGuid;
  
    console.log(newData)
      const response = await client.post(`HC/creaceHCPaciente`, newData, config)
      console.log(response)
      history(`/modulos`)
    } catch (e) {
      console.log(e)
    }
  }

  // Crear parte de medico
  const crearHistorialMedico = async (dataGM) => {
    // console.log(datosMedico)
    // console.log(dataGM)
    try {
      const config = {
        headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}` }
      };
      const response = await client.post(`HC/CreateDRPartHC`, dataGM, config)
      console.log(response);
      history(`/medico`)
    } catch (e) {
      console.log(e)
    }
  }

  //
  const [laborales, setLaborales] = useState({
    antlId: 0,//dejar por default
    antlGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",//dejar por default
    antlAnio: 0,
    antlUsuarioFk: 0,//dejar por default
    antlAntiguedad: "",
    antlEmpresa: "",
    antlActividad: "",
    antlDuracion: "",
    antlAgentesExposicion: "",
    antlDuracionExposicion: "",
    antlAntecedentesEnfProfesionales: "",
    antlIncapacidades: "",
    antlHcFk: 0
  });
  const [antecedentesLaborales, setAntecedentesLaborales] = useState([]);
  useEffect(() => {
    datosUser();
    if (localStorage.getItem('AvancePaciente') != null) {
      console.log("Hay avance")
      // console.log(localStorage.getItem('AvancePaciente'))
      setDataAvance(JSON.parse(localStorage.getItem('AvancePaciente')))
    }
  }, [])



  useEffect(() => {
    // console.log(datosMedico)
  }, [datosMedico])

  useEffect(() => {
    // console.log(datos)
  }, [datos])

  return (
    <HistorialClinicoContext.Provider
      value={{
        datos,
        datosMedico,
        setDatos,
        dataUser,
        dataAvance,
        handleChangeClinico,
        handleChangeClinicoMed,
        handleChangeServicios,
        listaUsuariosL,
        usuariosListos,
        guardarHistorialPaciente,
        guardarHistorialMedico,
        userExam,
        laborales,
        setLaborales,
        antecedentesLaborales,
        setAntecedentesLaborales,
        obtenerHistorialPaciente,
        historialPaciente,
        historialAnio,
        obtenerHistorialAnio,
        setHistorialAnio,
        datosMedico,
        handleChangeFirmaM,
        obtenerHistorialFecha,
        historialFecha,
        firmaPaciente,
        setFirmaPaciente,
        guardarFirmaPaciente
      }}
    >
      {children}
    </HistorialClinicoContext.Provider>
  )
}

export {
  HistorialClinicoProvider
}

export default HistorialClinicoContext