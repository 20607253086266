import axios from "axios";
import { createContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import useAuth from "../hooks/useAuth";
import  secureLocalStorage  from  "react-secure-storage";
const UserContext = createContext()

const client = axios.create({
    baseURL: "https://appsexpedientemedico.azurewebsites.net/api"
});

const UserProvider = ({ children }) => {
    const navigate = useNavigate()

    const { validarToken } = useAuth()

    const [error, setError] = useState({
        band: false,
        texto: ''
    })
    const [bandArchivos, setBandArchivos] = useState(false)
    const [archivos, setArchivos] = useState(null)
    const [datosUsuario, setDatosUsuario] = useState({
        userGuid: '',
        userNombre: '',
        userSApellido: '',
        userEmail: '',
        userPhone: 0,
        userAge: 0,
        userStreet: '',
        userNext: "",
        userNint: '',
        userGenderId: 0,
        userRolId: 0,
        medCedula: '',
        medLogo: '',
        medTitulo: '',
        medUniversidad: ''
    })

    const [roles, setRoles] = useState([])

    const [loadTime, setLoadTime] = useState(false)

    const getInicial = async () => {
        try {
            setLoadTime(true)
            validarToken()


            let tokens =secureLocalStorage.getItem('tokenid');
            const config = {
                headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}`}
            };  
            const response = await client.post(`/rol/getroles`,``, config)
            setRoles(response.data.response)

            setLoadTime(false)
        } catch (e) {
            setLoadTime(false)
        }
    }

    const handleChangeRol = e => {
        setDatosUsuario({
            ...datosUsuario,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        getInicial()
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        let json = localStorage.getItem('jsondatosuser')
        const json2 = JSON.parse(json)

        setDatosUsuario({
            userGuid: json2 != null ? json2.userGuid : '',
            userNombre: json2 != null ? json2.userNombre : '',
            userPApellido: json2 != null ? json2.userPApellido : '',
            userSApellido: json2 != null ? json2.userSApellido : '',
            userEmail: json2 != null ? json2.userEmail : '',
            userPhone: json2 != null ? json2.userPhone : 0,
            userAge: json2 != null ? json2.userAge : 0,
            userStreet: json2 != null ? json2.userStreet : '',
            userNext: json2 != null ? json2.userNext : '',
            userNint: json2 != null ? json2.userNint : '',
            userGenderId: json2 != null ? json2.userGenderId : 0,
            userRolId: json2 != null ? json2.userRolId : 0,
            medCedula: json2 != null ? json2.medCedula : '',
            medLogo: json2 != null ? json2.medLogo : '',
            medTitulo: json2 != null ? json2.medTitulo : '',
            medUniversidad: json2 != null ? json2.medUniversidad : '',
        })

    }, [roles])

    const updateRol = async () => {
        setLoadTime(true)
        try {
            setLoadTime(true)
            validarToken()


            let tokens = secureLocalStorage.getItem('tokenid');
            const getGuid = roles.find(e => e.rolId === datosUsuario.userRolId)
            // eslint-disable-next-line
            const config = {
                headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}`}
            };
            const data = {
                "userG": datosUsuario.userGuid, //GUID del usuario
                "rolG": getGuid.rolGuid //GUID del rol
            }
            const response = await client.post(`/admin/updateUserRol`,data,config)

            if (getGuid.rolId === 2) {
                const json = {
                    "medId": 0,
                    "userGuid": datosUsuario.userGuid, //Este campo se pedio por separado como parametro antes llamada medGUID, es el guid del usuario que tiene rol de DR
                    "medGuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6", //DEJAR ASI POR DEFAULT

                    "medUniversidad": datosUsuario.medUniversidad !== null ? datosUsuario.medUniversidad : 's',
                    "medCedula": datosUsuario.medCedula !== null ? datosUsuario.medCedula : 's',
                    "medLogo": datosUsuario.medLogo !== null ? datosUsuario.medLogo : 's',
                    
                    "medIdUser": 0, //DEJAR ASI POR DEFAULT
                    "medDcreate": "2023-07-10T19:21:55.769Z", //DEJAR ASI POR DEFAULT
                    "medStatus": 0, //DEJAR ASI POR DEFAULT
                    "medTitulo": datosUsuario.medTitulo !== null ? datosUsuario.medTitulo : 's',
                    "MedFirma": ''
                }
                console.log(json)
                console.log(tokens);
                // eslint-disable-next-line
                const response = await client.post(`/admin/updateDR`, json, config)
            }


            navigate('/admin')
            //setLoadTime(false)
        } catch (e) {
            console.log(e);
            setLoadTime(false)
        }
    }
    const subirArchivos = e => {
        setArchivos(e)

        var reader = new FileReader()
        if (e !== null) {
            reader.readAsDataURL(e[0])
            reader.onload = () => {
                var base64 = reader.result
                setDatosUsuario({
                    ...datosUsuario,
                    medLogo: base64
                })
            }
        } else {
            setDatosUsuario({
                ...datosUsuario,
                medLogo: ''
            })
        }
    }
    return (
        <UserContext.Provider
            value={{
                setError,
                error,
                loadTime,
                datosUsuario,
                roles,
                handleChangeRol,
                updateRol,
                setLoadTime,
                setBandArchivos,
                bandArchivos,
                subirArchivos,
                archivos
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export {
    UserProvider
}

export default UserContext