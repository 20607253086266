import { useState } from 'react';
import {
    Grid,
    TextField,
    Button
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import TablaDiagnostico from './TablaDiagnostico';
import TablaSintomas from './TablaSintomas';

import useConsulta from '../../../hooks/useConsulta';

const DatosConsulta = () => {
    const { datos, handleChangeDatos, setDatos, setBandSintomas, setError  } = useConsulta()
    const { pacienteExploracion, pacientePlan, pacienteLaboratorio, sintomas,reRecomendaciones } = datos


    const [sintomaText, setSintomaText] = useState('')

    const handleChange = e => {
        setSintomaText(e.target.value)
    }

    const handleClickOpen = async () => {
        try {
            if (sintomaText !== '') {
                setDatos({
                    ...datos,
                    sintomas: [
                        ...sintomas,
                        {
                            id: uuidv4(),
                            sintomanombre: sintomaText
                        }
                    ]
                })
                const datos2 = {
                    ...datos,
                    sintomas: [
                        ...sintomas,
                        {
                            id: uuidv4(),
                            sintomanombre: sintomaText
                        }
                    ]
                }
                localStorage.setItem('jsonexpmed', JSON.stringify(datos2))
                setSintomaText('')
                setBandSintomas(false);
            } else {

                setError({ band: true, texto: 'Debes ingresar el síntoma' })
            }


        } catch (e) {
            setBandSintomas(false);
            setError({ band: false, texto: '' })

        }
    };
    return (
        <Grid container spacing={2}>
            <Grid item lg={1} sm={2} xs={12}>
                <Button variant="contained" style={{ backgroundColor: "#008aa7" }} onClick={handleClickOpen}>Agregar</Button>
            </Grid>
            <Grid style={{ marginLeft: 10 }} item lg={10} sm={2} xs={12}>
                <TextField
                    name="sintomaText"
                    fullWidth
                    multiline
                    id="sintomaText"
                    label="Motivo de consulta"
                    autoFocus
                    margin='none'
                    size='small'
                    value={sintomaText}
                    onChange={handleChange}
                    // inputProps={{
                    //     style: {
                    //         height: "20px",
                    //     },
                    // }}
                    required
                />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
                <TablaSintomas />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
                <TextField
                    multiline
                    fullWidth
                    id="pacienteExploracion"
                    label="Exploración física"
                    name="pacienteExploracion"
                    autoComplete="family-name"
                    value={pacienteExploracion}
                    onChange={handleChangeDatos}
                    required
                />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
                <TextField
                    multiline
                    fullWidth
                    id="pacienteLaboratorio"
                    label="Estudios de gabinete y laboratorio"
                    name="pacienteLaboratorio"
                    autoComplete="family-name"
                    value={pacienteLaboratorio}
                    onChange={handleChangeDatos}                    
                />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
                <TextField
                    multiline
                    fullWidth
                    required
                    size='small'
                    id="reRecomendaciones"
                    label="Recomendaciones"
                    name="reRecomendaciones"
                    style={{ marginTop: "15px" }}
                    value={reRecomendaciones}
                    onChange={handleChangeDatos}

                />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
                <TablaDiagnostico />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
                <TextField
                    multiline
                    fullWidth
                    id="pacientePlan"
                    label="Plan"
                    name="pacientePlan"
                    autoComplete="family-name"
                    value={pacientePlan}
                    onChange={handleChangeDatos}
                    required
                />
            </Grid>

        </Grid>

    )
}

export default DatosConsulta