//importar librerias

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Container,
  CssBaseline,
  Box,
  Typography,
  Grid,
  TextField
} from '@mui/material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Load from './Load';

//importar funciones
import useAdmin from '../../hooks/useAdmin';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    redcolor: createColor('#ba000d')
  }
});

//import { validarToken } from '../../../helpers/auth'
export default function Horarios() {


  const { loadTime, bandHorarios, setBandHorarios, horario, handleChangeHorario, actualizarHorario } = useAdmin()

  const { horarioInicial, horarioFinal } = horario

  const handleClickOpen = async () => {
    await actualizarHorario()

  };

  const handleClose = () => {
    setBandHorarios(false);
  };

  return (
    <div>

      <Load />
      {loadTime === false ? (
        <Dialog
          open={bandHorarios}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Horario para citas"}
          </DialogTitle>
          <ThemeProvider theme={theme}>

            <Container component="main">
              <CssBaseline />

              <Box component="form" sx={{ mt: 3 }}>

                <Grid container spacing={2}>
                  <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                      required
                      name="horarioInicial"
                      fullWidth
                      id="horarioInicial"
                      label="Horario de inicio"
                      autoFocus
                      value={horarioInicial}
                      onChange={handleChangeHorario}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                      required
                      name="horarioFinal"
                      fullWidth
                      id="horarioFinal"
                      label="Horario de cierre"
                      autoFocus
                      value={horarioFinal}
                      onChange={handleChangeHorario}
                    />
                  </Grid>
                </Grid>
              </Box>

            </Container>
          </ThemeProvider>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleClickOpen} autoFocus>
              Actualizar
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
}