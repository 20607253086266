import { createContext, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { decodeToken } from "react-jwt";
import axios from 'axios'
import  secureLocalStorage  from  "react-secure-storage";
const LoginContext = createContext()

const client = axios.create({
    baseURL: "https://appsexpedientemedico.azurewebsites.net/api"
  });

const LoginProvider = ({ children }) => {
    const navigate = useNavigate()
    const [error, setError] = useState('')
    const [ loadTime, setLoadTime ] = useState(false)
    const loginUser = async (mail, pass) => {

        setTimeout(async () => {
            setLoadTime(true)

            try {
                const data = {
                    "mail": mail,
                    "password": pass
                }
                const response = await client.post(`/users/login`, data, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                        'Content-Type': 'application/json',
                    }
                });

                if (response.data.code === 400) {
                    setError(response.data.response)
                    setLoadTime(false)
                } else {
                    secureLocalStorage.setItem("tokenid", response.data.response)
                    const idRol = decodeToken(response.data.response)['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                   
                    if(idRol==1){
                        navigate('/modulos')
                    }else if (idRol==2){
                        navigate('/medico')
                    }else if (idRol==3){
                        navigate('/admin')
                    }else{
                        setError('Correo o contraseña incorrectos')
                    }
                    setLoadTime(false)
                    

                    setError('')
                }
                setLoadTime(false)

            } catch (e) {
                setError('Correo o contraseña incorrectos')
                setLoadTime(false)
            }
        }, 0);
    }
    return (
        <LoginContext.Provider
            value={{
                loginUser,
                error,
                loadTime
            }}
        >
            {children}
        </LoginContext.Provider>
    )
}

export {
    LoginProvider
}

export default LoginContext