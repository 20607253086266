import { forwardRef, useEffect, useState } from 'react'

import {
    Radio,
    RadioGroup,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    InputLabel,
    Input,
    TextField,
    Slide
} from '@mui/material';
import useHistorialClinico from '../../../hooks/useHistorialClinico';


const Patologicos = () => {

    const { datos, handleChangeClinico} = useHistorialClinico()
    const { antPatologicos } = datos

    //Primera parte
    const [alergias, setAlergias] = useState(false);
    const [asm, setAsm] = useState(false);
    const [can, setCan] = useState(false);
    const [cirugias, setCirugias] = useState(false)
    const [conv, setConv] = useState(false);
    const [desmayos, setDesmayos] = useState(false);
    const [diab, setDiab] = useState(false);
    const [dolores, setDolores] = useState(false);
    const [comunicacion, setComunicacion] = useState(false);
    const [vision, setVision] = useState(false);
    const [audicion, setAudicion] = useState(false);
    const [piel, setPiel] = useState(false);
    const [pulmones, setPulmones] = useState(false);
    const [rin, setRin] = useState(false);
    const [cor, setCor] = useState(false);
    const [higado, setHigado] = useState(false);
    const [fracturas, setFracturas] = useState(false);
    const [fuma, setFuma] = useState(false);
    const [hip, setHip] = useState(false);
    const [alcohol, setAlcohol] = useState(false);
    const [varices, setVarices] = useState(false);

    //Segunda Parte
    const [sarampion, setSarampion] = useState(false);
    const [varicela, setVaricela] = useState(false);
    const [rubeola, setRubeola] = useState(false);
    const [herpes, setHerpes] = useState(false);
    const [paperas, setPaperas] = useState(false);
    const [hepatitis, setHepatitis] = useState(false);
    const [transfusiones, setTransfusiones] = useState(false);
    const [gastritis, setGastritis] = useState(false);
    const [colitis, setColitis] = useState(false);
    const [hernias, setHernias] = useState(false);
    const [autoinmunes, setAutoinmunes] = useState(false);
    const [movilidad, setMovilidad] = useState(false);
    const [nacimiento, setNacimiento] = useState(false);
    const [medicamento, setMedicamento] = useState(false);
    const [depresion, setDepresion] = useState(false);
    const [terapia, setTerapia] = useState(false);
    const [otras, setOtras] = useState(false);
    const [senas, setSenas] = useState(false);



    return (

        <Grid container spacing={1} p={1} style={{ backgroundColor:"#FFF" }}>
            {/*------------ Alergias --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Alergias</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaAlergiasPa"
                            value={antPatologicos.antpaAlergiasPa!=""?antPatologicos.antpaAlergiasPa:"No"}
                            onChange={(e) => { setAlergias(!alergias); handleChangeClinico(e) }}
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {alergias ?
                    <Slide direction="left" in={alergias} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Asma --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Asma (silba el pecho)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaAsmaPa"
                            value={antPatologicos.antpaAsmaPa!=""?antPatologicos.antpaAsmaPa:"No"}
                            onChange={(e) => { setAsm(!asm); handleChangeClinico(e) }}
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {asm ?
                    <Slide direction="left" in={asm} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Cancer --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Cáncer</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaCancerPa"
                            value={antPatologicos.antpaCancerPa!=""?antPatologicos.antpaCancerPa:"No"}
                            onChange={(e) => { setCan(!can); handleChangeClinico(e)  }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {can ?
                    <Slide direction="left" in={can} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Cirugias --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Cirugías</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="antPatologicos,antpaCirugiasPa"
                            value={antPatologicos.antpaCancerPa!=""?antPatologicos.antpaCirugiasPa:"No"}
                            onChange={(e) => { setCirugias(!cirugias); handleChangeClinico(e)  }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {cirugias ?
                    <Slide direction="left" in={cirugias} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Convulsiones --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Convulsiones</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaConvulsionesPa"
                            value={antPatologicos.antpaConvulsionesPa!=""?antPatologicos.antpaConvulsionesPa:"No"}
                            onChange={(e) => { setConv(!conv); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {conv ?
                    <Slide direction="left" in={conv} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Desmayos --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Desmayos</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaDesmayosPa"
                            value={antPatologicos.antpaDesmayosPa!=""?antPatologicos.antpaDesmayosPa:"No"}
                            onChange={(e) => { setDesmayos(!desmayos); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {desmayos ?
                    <Slide direction="left" in={desmayos} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Diabetes--------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Diabetes (azúcar elevada)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaDiabetesPa"
                            value={antPatologicos.antpaDiabetesPa!=""?antPatologicos.antpaDiabetesPa:"No"}
                            onChange={(e) => { setDiab(!diab); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            <Grid item lg={7} sm={7} xs={12}>
                {diab ?
                    <Slide direction="left" in={diab} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Dolores --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}> Dolor constante de cuello o espalda baja</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaDolorEspaldaCuelloPa"
                            value={antPatologicos.antpaDolorEspaldaCuelloPa!=""?antPatologicos.antpaDolorEspaldaCuelloPa:"No"}
                            onChange={(e) => { setDolores(!dolores); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {dolores ?
                    <Slide direction="left" in={dolores} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Comunicacion--------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Limitación en la comunicación oral y escrita</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaLimitacionOralEscritaPa"
                            value={antPatologicos.antpaLimitacionOralEscritaPa!=""?antPatologicos.antpaLimitacionOralEscritaPa:"No"}
                            onChange={(e) => { setComunicacion(!comunicacion); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            <Grid item lg={7} sm={7} xs={12}>
                {comunicacion ?
                    <Slide direction="left" in={comunicacion} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Vision --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Disminución de la visión</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="antPatologicos,antpaDisminucionVisualPa"
                            value={antPatologicos.antpaDisminucionVisualPa!=""?antPatologicos.antpaDisminucionVisualPa:"No"}
                            onChange={(e) => { setVision(!vision); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {vision ?
                    <Slide direction="left" in={vision} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Audicion --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Disminución en audición </FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="antPatologicos,antpaDisminucionAudicionPa"
                            value={antPatologicos.antpaDisminucionAudicionPa!=""?antPatologicos.antpaDisminucionAudicionPa:"No"}
                            onChange={(e) => { setAudicion(!audicion); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {audicion ?
                    <Slide direction="left" in={audicion} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Piel --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Enfermedades De la piel</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                           name="antPatologicos,antpaEnfPielPa"
                            value={antPatologicos.antpaEnfPielPa!=""?antPatologicos.antpaEnfPielPa:"No"}
                            onChange={(e) => { setPiel(!piel); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {piel ?
                    <Slide direction="left" in={piel} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Pulmones --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Enfermedades De los pulmones</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaEnfPulmonesPa"
                            value={antPatologicos.antpaEnfPulmonesPa!=""?antPatologicos.antpaEnfPulmonesPa:"No"}
                            onChange={(e) => { setPulmones(!pulmones); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {pulmones ?
                    <Slide direction="left" in={pulmones} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Riñon --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Enfermedades De los riñones</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaEnfRinonesPa"
                            value={antPatologicos.antpaEnfRinonesPa!=""?antPatologicos.antpaEnfRinonesPa:"No"}
                            onChange={(e) => { setRin(!rin); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {rin ?
                    <Slide direction="left" in={rin} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Corazon --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Enfermedades Del corazón</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                              name="antPatologicos,antpaEnfCorazonPa"
                            value={antPatologicos.antpaEnfCorazonPa!=""?antPatologicos.antpaEnfCorazonPa:"No"}
                            onChange={(e) => { setCor(!cor); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {cor ?
                    <Slide direction="left" in={cor} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Higado --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Enfermedades del hígado</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                           name="antPatologicos,antpaEnfHigadoPa"
                            value={antPatologicos.antpaEnfHigadoPa!=""?antPatologicos.antpaEnfHigadoPa:"No"}
                            onChange={(e) => { setHigado(!higado); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {higado ?
                    <Slide direction="left" in={higado} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Fracturas --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Fracturas, Esguinces o Amputación</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaFracturasEsguincesAmputacionPa"
                            value={antPatologicos.antpaFracturasEsguincesAmputacionPa!=""?antPatologicos.antpaFracturasEsguincesAmputacionPa:"No"}
                            onChange={(e) => { setFracturas(!fracturas); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {fracturas ?
                    <Slide direction="left" in={fracturas} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Fuma --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Fuma</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="antPatologicos,antpaFumaPa"
                            value={antPatologicos.antpaFumaPa!=""?antPatologicos.antpaFumaPa:"No"}
                            onChange={(e) => { setFuma(!fuma); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {fuma ?
                    <Slide direction="left" in={fuma} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Hipertensión (presión alta)--------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Hipertensión (presión alta)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaHipertensionPa"
                            value={antPatologicos.antpaHipertensionPa!=""?antPatologicos.antpaHipertensionPa:"No"}
                            onChange={(e) => { setHip(!hip); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {hip ?
                    <Slide direction="left" in={hip} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Toma --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Toma bebidas alcohólicas</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaBebidasAlcoholicasPa"
                            value={antPatologicos.antpaBebidasAlcoholicasPa!=""?antPatologicos.antpaBebidasAlcoholicasPa:"No"}
                            onChange={(e) => { setAlcohol(!alcohol); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {alcohol ?
                    <Slide direction="left" in={alcohol} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Varices --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Várices</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="antPatologicos,antpaVaricesPa"
                            value={antPatologicos.antpaVaricesPa!=""?antPatologicos.antpaVaricesPa:"No"}
                            onChange={(e) => { setVarices(!varices); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {varices ?
                    <Slide direction="left" in={varices} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Sarampion --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Sarampión</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="antPatologicos,antpaSarampionPa"
                            value={antPatologicos.antpaSarampionPa!=""?antPatologicos.antpaSarampionPa:"No"}
                            onChange={(e) => { setSarampion(!sarampion); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {sarampion ?
                    <Slide direction="left" in={sarampion} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Varicela --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Varicela</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaVaricelaPa"
                            value={antPatologicos.antpaVaricelaPa!=""?antPatologicos.antpaVaricelaPa:"No"}
                            onChange={(e) => { setVaricela(!varicela); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {varicela ?
                    <Slide direction="left" in={varicela} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Rubeola --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Rubeóla</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaRubeolaPa"
                            value={antPatologicos.antpaRubeolaPa!=""?antPatologicos.antpaRubeolaPa:"No"}
                            onChange={(e) => { setRubeola(!rubeola); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {rubeola ?
                    <Slide direction="left" in={rubeola} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Herpes --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Herpes</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="antPatologicos,antpaHerpesPa"
                            value={antPatologicos.antpaHerpesPa!=""?antPatologicos.antpaHerpesPa:"No"}
                            onChange={(e) => { setHerpes(!herpes); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {herpes ?
                    <Slide direction="left" in={herpes} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Paperas --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Parotiditis (paperas)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="antPatologicos,antpaParotiditisPa"
                            value={antPatologicos.antpaParotiditisPa!=""?antPatologicos.antpaParotiditisPa:"No"}
                            onChange={(e) => { setPaperas(!paperas); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {paperas ?
                    <Slide direction="left" in={paperas} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Hepatitis --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Hepatitis</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaHepatitisPa"
                            value={antPatologicos.antpaHepatitisPa!=""?antPatologicos.antpaHepatitisPa:"No"}
                            onChange={(e) => { setHepatitis(!hepatitis); handleChangeClinico(e)  }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {hepatitis ?
                    <Slide direction="left" in={hepatitis} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Transfusiones --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Transfusiones</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="antPatologicos,antpaTransfusionesPa"
                            value={antPatologicos.antpaTransfusionesPa!=""?antPatologicos.antpaTransfusionesPa:"No"}
                            onChange={(e) => { setTransfusiones(!transfusiones); handleChangeClinico(e)  }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {transfusiones ?
                    <Slide direction="left" in={transfusiones} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Gastritis --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Gastritis</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaGastritisPa"
                            value={antPatologicos.antpaGastritisPa!=""?antPatologicos.antpaGastritisPa:"No"}
                            onChange={(e) => { setGastritis(!gastritis) ; handleChangeClinico(e)}}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {gastritis ?
                    <Slide direction="left" in={gastritis} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Colitis --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Colitis</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaColitisPa"
                            value={antPatologicos.antpaColitisPa!=""?antPatologicos.antpaColitisPa:"No"}
                            onChange={(e) => { setColitis(!colitis); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {colitis ?
                    <Slide direction="left" in={colitis} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------hernias --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Hernias</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaHerniasPa"
                            value={antPatologicos.antpaHerniasPa!=""?antPatologicos.antpaHerniasPa:"No"}
                            onChange={(e) => { setHernias(!hernias); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {hernias ?
                    <Slide direction="left" in={hernias} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>

            {/*------------ Auto --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Autoinmunes</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaAutoinmunesPa"
                            value={antPatologicos.antpaAutoinmunesPa!=""?antPatologicos.antpaAutoinmunesPa:"No"}
                            onChange={(e) => { setAutoinmunes(!autoinmunes); handleChangeClinico(e)  }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {autoinmunes ?
                    <Slide direction="left" in={autoinmunes} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Limitacion --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Limitación de movilidad </FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaLimitacionMovilidadPa"
                            value={antPatologicos.antpaLimitacionMovilidadPa!=""?antPatologicos.antpaLimitacionMovilidadPa:"No"}
                            onChange={(e) => { setMovilidad(!movilidad); handleChangeClinico(e)  }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {movilidad ?
                    <Slide direction="left" in={movilidad} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Problemas al nacimiento  --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Problemas al nacimiento  </FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaProblemasNacimientoPa"
                            value={antPatologicos.antpaProblemasNacimientoPa!=""?antPatologicos.antpaProblemasNacimientoPa:"No"}
                            onChange={(e) => { setNacimiento(!nacimiento); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {nacimiento ?
                    <Slide direction="left" in={nacimiento} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------medicamento  --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Consume algún medicamento</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                           name="antPatologicos,antpaConsumeMedicamentoPa"
                            value={antPatologicos.antpaConsumeMedicamentoPa!=""?antPatologicos.antpaConsumeMedicamentoPa:"No"}
                            onChange={(e) => { setMedicamento(!medicamento) ; handleChangeClinico(e)}}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {medicamento ?
                    <Slide direction="left" in={medicamento} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Depresion  --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Ha tenido depresión o ansiedad</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaDepresionAnsiedadPa"
                            value={antPatologicos.antpaDepresionAnsiedadPa!=""?antPatologicos.antpaDepresionAnsiedadPa:"No"}
                            onChange={(e) => { setDepresion(!depresion); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {depresion ?
                    <Slide direction="left" in={depresion} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Terapia  --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Ha recibido algún tipo de terapia</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaTerapiaPa"
                            value={antPatologicos.antpaTerapiaPa!=""?antPatologicos.antpaTerapiaPa:"No"}
                            onChange={(e) => { setTerapia(!terapia); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {terapia ?
                    <Slide direction="left" in={terapia} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
             {/*------------Otros  --------------- */}
             <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Otras</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antPatologicos,antpaOtrasPa"
                            value={antPatologicos.antpaOtrasPa!=""?antPatologicos.antpaOtrasPa:"No"}
                            onChange={(e) => { setOtras(!otras); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {otras ?
                    <Slide direction="left" in={otras} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Señas  --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="Senas" style={{ marginRight: "10px", width: "30%" }}>Señas particulares </FormLabel>
                        <span id="Senas" style={{ fontSize: "0.6em", fontStyle: "italic" }}> (tatuajes, perforaciones, cicatrices o marcas de nacimiento)</span>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="Senas"
                           name="antPatologicos,antpaSeniasParticularesPa"
                            value={antPatologicos.antpaSeniasParticularesPa!=""?antPatologicos.antpaSeniasParticularesPa:"No"}
                            onChange={(e) => { setSenas(!senas); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            <FormControlLabel value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {senas ?
                    <Slide direction="left" in={senas} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="medicoTitulo"
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
        </Grid>
    )
}

export default Patologicos