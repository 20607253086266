import React, { useState } from "react";
import "antd/dist/antd";
import '../../../styles/ArchivoConfDatos.css';
import { Modal, Button } from "antd";
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import PDF from "react-pdf-js";
const ArchivoConfDatos = ({ pdf, onCancel, visible, setOpen }) => {

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [scale, setScale] = useState(1);

  const onDocumentComplete = (numPages) => {
    setPages(numPages)
  }

  const onDocumentError = (err) => {
    console.error('pdf viewer error:', err);
  }

  const onSetScale = (type) => {

    var newScale = type ? scale + 0.1 : scale - 0.1;

    if (newScale > 2) {
      newScale = 2
    } else if (newScale < 0.1) {
      newScale = 0.1
    }

    setScale(newScale)

  }

  const onPage = (type) => {

    var newPage = type ? page + 1 : page - 1

    if (newPage > pages) {
      newPage = 1
    } else if (newPage < 1) {
      newPage = pages
    }

    setPage(newPage)
  }

  const zoomStyle = {
    marginLeft: 10,
    cursor: 'pointer'
  }

  //Submodal
  const ChildModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setPage(1);
      onCancel();
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);

    };
    return (
      <>
        <Button type="primary" onClick={showModal}>
          Finalizar
        </Button>
        <Modal
          title="Acepto los términos y condiciones de confidencialidad."
          open={isModalOpen}
          cancelText="Cancelar"
          okText="Aceptar"
          onOk={handleOk}
          onCancel={handleCancel}>
        </Modal>
      </>
    );
  };

  const footer = <div className="footer">
    <Button onClick={() => onPage(0)}>Anterior</Button>
    <div>
      <span style={{ textAlign: 'center' }}>Pagina {page} de {pages}</span>
      <ZoomOutOutlined style={{ ...zoomStyle, opacity: scale === 0.1 ? 0.5 : 1 }} onClick={() => onSetScale(0)} />
      <ZoomInOutlined style={{ ...zoomStyle, opacity: scale === 2 ? 0.5 : 1 }} onClick={() => onSetScale(1)} />
      <span>{Math.round(scale * 100)}%</span>
    </div>
    {page == pages ?
      <ChildModal /> :
      <Button onClick={() => onPage(1)}>Siguiente</Button>
    }
  </div>
  
  return (<Modal maskClosable={(false)}
    onCancel={onCancel}
    open={visible}
    width={"50%"}
    bodyStyle={{ height: '100%', overflowY: 'auto' }}
    style={{ top: 20 }}
    footer={footer}

  >
    <div className="pdfWrapper">
      <PDF
        file={pdf}
        onDocumentComplete={onDocumentComplete}
        onDocumentError={onDocumentError}
        page={page}
        scale={scale}
      />
    </div>
  </Modal>)

};
export default ArchivoConfDatos;