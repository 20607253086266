import { forwardRef, useEffect, useState } from 'react'

import {
    Radio,
    RadioGroup,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    InputLabel,
    Input,
    TextField,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import useHistorialClinico from '../../../hooks/useHistorialClinico';

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const CabeceraD = () => {

    const { historialAnio, dataUser, handleChangeClinicoMed,setHistorialAnio} = useHistorialClinico()
    
    useEffect(() =>{
        var a = JSON.parse(localStorage.getItem('HistorialAnio'));
        setHistorialAnio(a)
        console.log(a)
    },[])

    useEffect(() =>{
        console.log(historialAnio)
    },[historialAnio])

    return (
        <>
        {historialAnio!=undefined?
        <Grid container spacing={2} p={1} style={{backgroundColor:"#FFF"}}>
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center" }}>
                    <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px" }}>Tipo de examen: </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="historialclinico,hcTipo"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={historialAnio.historialclinico.hcTipo}
                        
                    >
                        <FormControlLabel disabled value={"Ingreso"} control={<Radio/>} label="Ingreso" />
                        <FormControlLabel disabled value={"Periodico"} control={<Radio/>} label="Periódico" />
                        <FormControlLabel disabled value={"Promocion"} control={<Radio/>} label="Promoción" />
                        <FormControlLabel disabled value={"Retiro"} control={<Radio/>} label="Retiro" />
                        <FormControlLabel disabled value={"Reingreso"} control={<Radio/>} label="Reingreso" />

                    </RadioGroup>
                </FormControl>
            </Grid> 
            {/* <Grid item lg={6} sm={6} xs={12}>
                <TextField
                    autoComplete="given-name"
                    name="historialclinico,hcPuesto"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={historialAnio.historialclinico.hcPuesto}
                    fullWidth
                    id="hcPuesto"
                    label="Puesto"
                    autoFocus
                    variant="standard"
                    disabled
                />
            </Grid> */}
            <Grid item lg={6} sm={6} xs={12}>
                <TextField
                    autoComplete="given-name"
                    name="area"
                    fullWidth
                    id="area"
                    value={historialAnio.puesto}
                    label="Área de trabajo"
                    autoFocus
                    variant="standard"
                    disabled
                />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
                <TextField
                    autoComplete="given-name"
                    name="historialclinico,hcAgenteAmbiental"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialAnio.historialclinico.hcAgenteAmbiental}
                    fullWidth
                    id="hcAgenteAmbiental"
                    // defaultValue={historialAnio.historialclinico.}
                    disabled
                    label="Agentes ambientales del puesto:"
                    autoFocus
                    variant="standard"
                />
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
                <TextField
                    autoComplete="given-name"
                    name="No"
                    fullWidth
                    id="No"
                    value={historialAnio.noempleado}
                    label="No Empleado (si aplica)"
                    autoFocus
                    variant="standard"
                    disabled
                />
            </Grid>
            
        </Grid>
        :null}
       </> 
    )
}

export default CabeceraD