import { createContext, useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const ContrasenaContext = createContext()

const client = axios.create({
    baseURL: "https://appsexpedientemedico.azurewebsites.net/api"
});

const ContrasenaProvider = ({ children }) => {

    const Ref = useRef(null);
    const navigate = useNavigate()
    const [error, setError] = useState({
        band: false,
        texto: ''
    })
    const [error2, setError2] = useState({
        band: false,
        texto: ''
    })
    const [band, setBand] = useState(0)
    const [loadTime, setLoadTime] = useState(false)
    const [timer, setTimer] = useState('00:00:00');

    const [datos, setDatos] = useState({
        correo: '',
        confirmarCorreo: '',
        codigo: '0',
        contrasena: '',
        confirmarContrasena: '',
        sendCodigo: ''
    })
    const [validPassword, setValidPassword] = useState({
        bandPass: false,
        textoPass: ''
    })
    useEffect(() => {
        if (datos.contrasena !== datos.confirmarContrasena) {
            setValidPassword({ bandPass: true, textoPass: 'Las contraseñas no coinciden' })
        } else {
            setValidPassword({ bandPass: false, textoPass: '' })
        }

        // eslint-disable-next-line
    }, [datos.contrasena, datos.confirmarContrasena])
    const handleChange = e => {
        setDatos({
            ...datos,
            [e.target.name]: e.target.value
        })
    }
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }
    const clearTimer = (e) => {  
        setTimer('00:00:59');

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);

        }, 1000)
        Ref.current = id;
        //
    }
    const startTimer = (e) => {
        let { total, hours, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {

            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )

        }

    }
    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 59);
        return deadline;
    }
    const onClickReset = () => {
        clearTimer(getDeadTime());
    }
    useEffect(() => {
        clearTimer(getDeadTime())
    }, []);
    useEffect(() => {
        if (band !== 0) {
            setError2({ band: true, texto: 'Se ha enviado un código a tu correo' });
        }
    }, [band]);

    const enviarCodigoApi = async() => {
        try{
            setTimer(true)
            const response = client.post(`/users/sendCode?mail=${datos.correo}`)
            setTimer(false)
        }catch(e){

        }
    }

    const actualizarContrasena = async() => {
        try{
            
            setLoadTime(true)
            const data ={
                "mail": datos.correo,
                "password": datos.contrasena,
                "code": datos.sendCodigo
            }
            const response = await client.post(`/users/resetPass`, data)
            navigate('/login')
            setLoadTime(false)
        }catch(e){
            setLoadTime(false)
            setError({band: true, texto: 'Código incorrecto'})
        }
    }

    return (
        <ContrasenaContext.Provider
            value={{
                error,
                setError,
                error2,
                setError2,
                loadTime,
                datos,
                setDatos,
                handleChange,
                timer,
                onClickReset,
                setTimer,
                setBand,
                enviarCodigoApi,
                validPassword,
                setValidPassword,
                actualizarContrasena
            }}
        >
            {children}
        </ContrasenaContext.Provider>
    )
}

export {
    ContrasenaProvider
}

export default ContrasenaContext