import { forwardRef, useEffect, useState } from 'react'

import {
    Grid,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import useHistorialClinico from '../../../hooks/useHistorialClinico';


const AntecedentesLaboralesD = () => {

    const { historialAnio,
        dataUser,
        handleChangeClinicoMed,
        laborales,
        setLaborales,
        antecedentesLaborales,
        setAntecedentesLaborales } = useHistorialClinico()
    const { antecedentesLaborares } = historialAnio;

    const handleLaboral = (e) => {
        console.log(e.target.value)
        console.log(e.target.name)

        const newData = { ...laborales };
        newData[e.target.name] = e.target.value;
        setLaborales(newData)
    }

    const agregarAntecedente = () => {
        console.log(antecedentesLaborales)
        const arr = [...antecedentesLaborales];
        console.log(arr)
        arr.push(laborales);
        setAntecedentesLaborales(arr)
    }
    
    const columns = [
        { id: 'antlEmpresa', label: 'Empresa' },
        { id: 'antlActividad', label: 'Actividad' },
        { id: 'antlDuracion', label: 'Duracion' },
        { id: 'antlAgentesExposicion', label: 'Agentes de Exposición' },
        { id: 'antlDuracionExposicion', label: 'Duracion de Exposición' },
        { id: 'antlAntecedentesEnfProfesionales', label: 'Accidentes o enf. Profesionaless' },
        { id: 'antlIncapacidades', label: 'Incapacidades' }

    ];

    return (
        <>
            <Grid container spacing={2} p={1} style={{ backgroundColor:"#FFF" }}>
                
                {/* Tabla */}
                <Grid item lg={12} sm={12} xs={12}>
                    <TableContainer >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align='center'
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {antecedentesLaborares
                                    .map((row) => {
                                        return (
                                            <TableRow
                                                key={row.userGuid}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">{row.antlEmpresa}</TableCell>
                                                <TableCell align="center">{row.antlActividad}</TableCell>
                                                <TableCell align="center">{row.antlDuracion}</TableCell>
                                                <TableCell align="center">{row.antlAgentesExposicion}</TableCell>
                                                <TableCell align="center">{row.antlDuracionExposicion}</TableCell>
                                                <TableCell align="center">{row.antlAntecedentesEnfProfesionales}</TableCell>
                                                <TableCell align="center">{row.antlIncapacidades}</TableCell>

                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    )
}

export default AntecedentesLaboralesD