
//importar librerias
import { useState, useEffect } from 'react';
import { Table, Button, Tooltip } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TablePagination from '@mui/material/TablePagination';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router-dom';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import useHistorialClinico from '../../../hooks/useHistorialClinico';
import HistoryIcon from '@mui/icons-material/History';
import useAuth from '../../../hooks/useAuth';

const TablaLista = () => {
    const history = useNavigate()

    const { setVentana } = useAuth()
    const { listaUsuariosL, usuariosListos, userExam} = useHistorialClinico();
    const columns = [
        { id: 'Nombre', label: 'Nombre' },
        { id: 'PrimerApellido', label: 'Primer apellido' },
        { id: 'SegundoApellido', label: 'Segundo apellido' },
        { id: 'Correo', label: 'Correo' },
        { id: 'detalles', label: 'Detalles' }
    ];
    // eslint-disable-next-line

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // const getConsulta = (e) => {
    //     let tokens = secureLocalStorage.getItem('tokenid');

    //     localStorage.setItem('idguidpaciente', e.userGuid)
    //     setVentana('/pacientes')
    //     history.push('/historialcitaspaciente')
    // }
    const getExamen = (e)=>{
        console.log("Consultando avance")
        userExam(e)
    }
    const getListaExamenes = (e)=>{
        console.log("Consultando listas")
        localStorage.setItem('guidBusquedaExamenes',JSON.stringify(e))
        history(`/anioslistahistorial`)
    }

    useEffect(() => {
        listaUsuariosL();
    }, [])

    useEffect(() => {
        // console.log(usuariosListos);
    }, [usuariosListos])
    return (
        <>
        {usuariosListos!=undefined?
        <Paper>
            {usuariosListos.message==="NotFound"?<span style={{float:"right",fontSize:"0.8em",color:"red", fontStyle:"italic"}}>Aun no hay usuarios que hayan iniciado su Examen Médico</span>:null}
            <TableContainer >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align='center'
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usuariosListos.response.pacientes
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow
                                        key={row.userGuid}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{row.usuario.userNombre}</TableCell>
                                        <TableCell align="center">{row.usuario.userPApellido}</TableCell>
                                        <TableCell align="center">{row.usuario.userSApellido}</TableCell>
                                        <TableCell align="center">{row.usuario.userEmail}</TableCell>
                                        <TableCell align='center' style={{display:"grid"}}>
                                            {row.status == 0 ?
                                                "Aun no inicia su examen" :row.status == 1?
                                                <Tooltip title="Continuar Examen Médico">
                                                    <Button onClick={() => getExamen(row.usuario.userGuid)} Tooltip={"Continuar examen"}>
                                                        <KeyboardDoubleArrowRightIcon />Parte Médico
                                                    </Button>
                                                </Tooltip>:row.status == 2?"Esperando confirmacion del paciente":"Examen Finalizado"}

                                            <Tooltip title="Historial de Examenes Médicos">
                                                <Button onClick={() => getListaExamenes(row.usuario)}>
                                                    <HistoryIcon />Historial
                                                </Button>
                                            </Tooltip>

                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody> 
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={usuariosListos.response.pacientes.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>:null}
        </>
    )
}

export default TablaLista