//importar librerias
import * as React from 'react';
import { useEffect } from 'react'
import {
    Container,
    Box,
    Grid,
    CssBaseline,
    Typography,
    Button,
    TextField
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { HistorialClinicoProvider } from '../../../context/HistorialClinicoProvider';

//importar imagenes
import bienestar from '../../../archivos/bienestar.png';
import saludOcupacional from '../../../archivos/SaludOcupacional.png';

//importar componentes
import Navbar from '../../Navbar';
import StepperClinico from './StepperClinico';

//importar funciones
import useAuth from '../../../hooks/useAuth';

//Stepper
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';



const steps = ['FICHA DE IDENTIFICACIÓN', 'ANTECEDENTES HEREDOFAMILIARES', 'ANTECEDENTES PERSONALES NO PATOLÓGICOS', 'ANTECEDENTES PERSONALES PATOLÓGICOS'];

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
const theme = createTheme({
    palette: {
        redcolor: createColor('#ba000d')
    }
});


export default function HistorialClinico() {    
    const { validarToken, setVentana } = useAuth()
    useEffect(() => {
        validarToken()
        setVentana('/modulos')
        // eslint-disable-next-line
    }, [])

   

    return (
        <div>
            
            <HistorialClinicoProvider>
                <ThemeProvider theme={theme}>
                    <Navbar />
                    <Container component="main">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Grid container >
                                <Grid item lg={3} sm={4} xs={12} style={{textAlign:"center", alignSelf:"center"}}>
                                    <img width={150} src={saludOcupacional} alt="logo bienestar" />
                                </Grid>
                                <Grid item lg={6} sm={4} xs={12} style={{textAlign:"center", alignSelf:"center"}}>
                                    <Typography component="h1" variant="h5" color={"#108CB1"}>
                                        EXÁMEN MÉDICO
                                    </Typography>
                                    <Typography component="h1" variant="subtitle2" >
                                        DEPARTAMENTO DE SALUD OCUPACIONAL
                                    </Typography>
                                </Grid>
                                <Grid item lg={3} sm={4} xs={12} style={{textAlign:"center", alignSelf:"center"}}>
                                    <img width={150} src={bienestar} alt="logo bienestar" />
                                </Grid>
                            </Grid>
                        </Box>

                        {/* Preguntas */}
                        <StepperClinico/>

                    </Container>

                </ThemeProvider>
            </HistorialClinicoProvider>
        </div>
    )

}