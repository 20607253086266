import { forwardRef, useEffect, useState } from 'react'

import {
    Radio,
    RadioGroup,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    InputLabel,
    Input,
    TextField,
    Slide
} from '@mui/material';
import useHistorialClinico from '../../../hooks/useHistorialClinico';


const PatologicosDF = () => {

    const { datosMedico, handleChangeClinico, handleChangeClinicoMed, historialFecha} = useHistorialClinico()
    // const { antecedentesPatologicos } = datosMedico
    const { antecedentesPatologicos } = historialFecha

    //Primera parte
    const [alergias, setAlergias] = useState(historialFecha.antecedentesPatologicos.antpaAlergiasPa==="Si"?true:false);
    const [asm, setAsm] = useState(historialFecha.antecedentesPatologicos.antpaAsmaPa==="Si"?true:false);
    const [can, setCan] = useState(historialFecha.antecedentesPatologicos.antpaCancerPa==="Si"?true:false);
    const [cirugias, setCirugias] = useState(historialFecha.antecedentesPatologicos.antpaCirugiasPa==="Si"?true:false)
    const [conv, setConv] = useState(historialFecha.antecedentesPatologicos.antpaConvulsionesPa==="Si"?true:false);
    const [desmayos, setDesmayos] = useState(historialFecha.antecedentesPatologicos.antpaDesmayosPa==="Si"?true:false);
    const [diab, setDiab] = useState(historialFecha.antecedentesPatologicos.antpaDiabetesPa==="Si"?true:false);
    const [dolores, setDolores] = useState(historialFecha.antecedentesPatologicos.antpaDolorEspaldaCuelloPa==="Si"?true:false);
    const [comunicacion, setComunicacion] = useState(historialFecha.antecedentesPatologicos.antpaLimitacionOralEscritaPa==="Si"?true:false);
    const [vision, setVision] = useState(historialFecha.antecedentesPatologicos.antpaDisminucionVisualPa==="Si"?true:false);
    const [audicion, setAudicion] = useState(historialFecha.antecedentesPatologicos.antpaDisminucionAudicionPa==="Si"?true:false);
    const [piel, setPiel] = useState(historialFecha.antecedentesPatologicos.antpaEnfPielPa==="Si"?true:false);
    const [pulmones, setPulmones] = useState(historialFecha.antecedentesPatologicos.antpaEnfPulmonesPa==="Si"?true:false);
    const [rin, setRin] = useState(historialFecha.antecedentesPatologicos.antpaEnfRinonesPa==="Si"?true:false);
    const [cor, setCor] = useState(historialFecha.antecedentesPatologicos.antpaEnfCorazonPa==="Si"?true:false);
    const [higado, setHigado] = useState(historialFecha.antecedentesPatologicos.antpaEnfHigadoPa==="Si"?true:false);
    const [fracturas, setFracturas] = useState(historialFecha.antecedentesPatologicos.antpaFracturasEsguincesAmputacionPa==="Si"?true:false);
    const [fuma, setFuma] = useState(historialFecha.antecedentesPatologicos.antpaFumaPa==="Si"?true:false);
    const [hip, setHip] = useState(historialFecha.antecedentesPatologicos.antpaHipertensionPa==="Si"?true:false);
    const [alcohol, setAlcohol] = useState(historialFecha.antecedentesPatologicos.antpaBebidasAlcoholicasPa==="Si"?true:false);
    const [varices, setVarices] = useState(historialFecha.antecedentesPatologicos.antpaVaricesPa==="Si"?true:false);

    //Segunda Parte
    const [sarampion, setSarampion] = useState(historialFecha.antecedentesPatologicos.antpaSarampionPa==="Si"?true:false);
    const [varicela, setVaricela] = useState(historialFecha.antecedentesPatologicos.antpaVaricelaPa==="Si"?true:false);
    const [rubeola, setRubeola] = useState(historialFecha.antecedentesPatologicos.antpaRubeolaPa==="Si"?true:false);
    const [herpes, setHerpes] = useState(historialFecha.antecedentesPatologicos.antpaHerpesPa==="Si"?true:false);
    const [paperas, setPaperas] = useState(historialFecha.antecedentesPatologicos.antpaParotiditisPa==="Si"?true:false);
    const [hepatitis, setHepatitis] = useState(historialFecha.antecedentesPatologicos.antpaHepatitisPa==="Si"?true:false);
    const [transfusiones, setTransfusiones] = useState(historialFecha.antecedentesPatologicos.antpaTransfusionesPa==="Si"?true:false);
    const [gastritis, setGastritis] = useState(historialFecha.antecedentesPatologicos.antpaGastritisPa==="Si"?true:false);
    const [colitis, setColitis] = useState(historialFecha.antecedentesPatologicos.antpaColitisPa==="Si"?true:false);
    const [hernias, setHernias] = useState(historialFecha.antecedentesPatologicos.antpaHerniasPa==="Si"?true:false);
    const [autoinmunes, setAutoinmunes] = useState(historialFecha.antecedentesPatologicos.antpaAutoinmunesPa==="Si"?true:false);
    const [movilidad, setMovilidad] = useState(historialFecha.antecedentesPatologicos.antpaLimitacionMovilidadPa==="Si"?true:false);
    const [nacimiento, setNacimiento] = useState(historialFecha.antecedentesPatologicos.antpaProblemasNacimientoPa==="Si"?true:false);
    const [medicamento, setMedicamento] = useState(historialFecha.antecedentesPatologicos.antpaConsumeMedicamentoPa==="Si"?true:false);
    const [depresion, setDepresion] = useState(historialFecha.antecedentesPatologicos.antpaDepresionAnsiedadPa==="Si"?true:false);
    const [terapia, setTerapia] = useState(historialFecha.antecedentesPatologicos.antpaTerapiaPa==="Si"?true:false);
    const [otras, setOtras] = useState(historialFecha.antecedentesPatologicos.antpaOtrasPa==="Si"?true:false);
    const [senas, setSenas] = useState(historialFecha.antecedentesPatologicos.antpaSeniasParticularesPa==="Si"?true:false);



    return (

        <Grid container spacing={1} p={1} style={{ backgroundColor:"#FFF" }}>
            {/*------------ Alergias --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Alergias</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaAlergiasPa"
                            value={historialFecha.antecedentesPatologicos.antpaAlergiasPa}
                            // onChange={(e) => { setAlergias(!alergias); handleChangeClinicoMed(e) }}
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {alergias ?
                    <Slide direction="left" in={alergias} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaAlergiasPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaAlergiasPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaAlergiasPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Asma --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Asma (silba el pecho)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaAsmaPa"
                            value={historialFecha.antecedentesPatologicos.antpaAsmaPa}
                            // onChange={(e) => { setAsm(!asm); handleChangeClinico(e) }}
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {asm ?
                    <Slide direction="left" in={asm} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="medicoTitulo"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaAsmaPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaAsmaPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Cancer --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Cáncer</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaCancerPa"
                            value={historialFecha.antecedentesPatologicos.antpaCancerPa}
                            onChange={(e) => { setCan(!can); handleChangeClinico(e)  }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {can ?
                    <Slide direction="left" in={can} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaCancerPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaCancerPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaCancerPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Cirugias --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Cirugías</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="historialFecha.antecedentesPatologicos,antpaCirugiasPa"
                            value={historialFecha.antecedentesPatologicos.antpaCancerPa}
                            onChange={(e) => { setCirugias(!cirugias); handleChangeClinico(e)  }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {cirugias ?
                    <Slide direction="left" in={cirugias} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaCirugiasPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaCirugiasPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaCirugiasPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Convulsiones --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Convulsiones</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaConvulsionesPa"
                            value={historialFecha.antecedentesPatologicos.antpaConvulsionesPa}
                            onChange={(e) => { setConv(!conv); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {conv ?
                    <Slide direction="left" in={conv} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaConvulsionesPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaConvulsionesPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaConvulsionesPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Desmayos --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Desmayos</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaDesmayosPa"
                            value={historialFecha.antecedentesPatologicos.antpaDesmayosPa}
                            onChange={(e) => { setDesmayos(!desmayos); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {desmayos ?
                    <Slide direction="left" in={desmayos} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaDesmayosPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaDesmayosPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaDesmayosPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Diabetes--------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Diabetes (azúcar elevada)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaDiabetesPa"
                            value={historialFecha.antecedentesPatologicos.antpaDiabetesPa}
                            onChange={(e) => { setDiab(!diab); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            <Grid item lg={7} sm={7} xs={12}>
                {diab ?
                    <Slide direction="left" in={diab} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaDiabetesPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaDiabetesPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaDiabetesPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Dolores --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}> Dolor constante de cuello o espalda baja</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaDolorEspaldaCuelloPa"
                            value={historialFecha.antecedentesPatologicos.antpaDolorEspaldaCuelloPa}
                            onChange={(e) => { setDolores(!dolores); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {dolores ?
                    <Slide direction="left" in={dolores} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaDolorEspaldaCuelloPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaDolorEspaldaCuelloPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaDolorEspaldaCuelloPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Comunicacion--------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Limitación en la comunicación oral y escrita</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaLimitacionOralEscritaPa"
                            value={historialFecha.antecedentesPatologicos.antpaLimitacionOralEscritaPa}
                            onChange={(e) => { setComunicacion(!comunicacion); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            <Grid item lg={7} sm={7} xs={12}>
                {comunicacion ?
                    <Slide direction="left" in={comunicacion} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaLimitacionOralEscritaPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaLimitacionOralEscritaPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaLimitacionOralEscritaPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Vision --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Disminución de la visión</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="historialFecha.antecedentesPatologicos,antpaDisminucionVisualPa"
                            value={historialFecha.antecedentesPatologicos.antpaDisminucionVisualPa}
                            onChange={(e) => { setVision(!vision); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {vision ?
                    <Slide direction="left" in={vision} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaDisminucionVisualPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaDisminucionVisualPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaDisminucionVisualPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Audicion --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Disminución en audición </FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="historialFecha.antecedentesPatologicos,antpaDisminucionAudicionPa"
                            value={historialFecha.antecedentesPatologicos.antpaDisminucionAudicionPa}
                            onChange={(e) => { setAudicion(!audicion); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {audicion ?
                    <Slide direction="left" in={audicion} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaDisminucionAudicionPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaDisminucionAudicionPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaDisminucionAudicionPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Piel --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Enfermedades De la piel</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                           name="historialFecha.antecedentesPatologicos,antpaEnfPielPa"
                            value={historialFecha.antecedentesPatologicos.antpaEnfPielPa}
                            onChange={(e) => { setPiel(!piel); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {piel ?
                    <Slide direction="left" in={piel} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaEnfPielPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaEnfPielPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaEnfPielPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Pulmones --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Enfermedades De los pulmones</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaEnfPulmonesPa"
                            value={historialFecha.antecedentesPatologicos.antpaEnfPulmonesPa}
                            // onChange={(e) => { setPulmones(!pulmones); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {pulmones ?
                    <Slide direction="left" in={pulmones} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaEnfPulmonesPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaEnfPulmonesPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaEnfPulmonesPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Riñon --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Enfermedades De los riñones</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaEnfRinonesPa"
                            value={historialFecha.antecedentesPatologicos.antpaEnfRinonesPa}
                            // onChange={(e) => { setRin(!rin); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {rin ?
                    <Slide direction="left" in={rin} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaEnfRinonesPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaEnfRinonesPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaEnfRinonesPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Corazon --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Enfermedades Del corazón</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                              name="historialFecha.antecedentesPatologicos,antpaEnfCorazonPa"
                            value={historialFecha.antecedentesPatologicos.antpaEnfCorazonPa}
                            // onChange={(e) => { setCor(!cor); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {cor ?
                    <Slide direction="left" in={cor} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaEnfCorazonPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaEnfCorazonPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaEnfCorazonPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Higado --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Enfermedades del hígado</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                           name="historialFecha.antecedentesPatologicos,antpaEnfHigadoPa"
                            value={historialFecha.antecedentesPatologicos.antpaEnfHigadoPa}
                            // onChange={(e) => { setHigado(!higado); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {higado ?
                    <Slide direction="left" in={higado} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaEnfHigadoPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaEnfHigadoPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaEnfHigadoPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Fracturas --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Fracturas, Esguinces o Amputación</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaFracturasEsguincesAmputacionPa"
                            value={historialFecha.antecedentesPatologicos.antpaFracturasEsguincesAmputacionPa}
                            // onChange={(e) => { setFracturas(!fracturas); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {fracturas ?
                    <Slide direction="left" in={fracturas} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaFracturasEsguincesAmputacionPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaFracturasEsguincesAmputacionPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaFracturasEsguincesAmputacionPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Fuma --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Fuma</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="historialFecha.antecedentesPatologicos,antpaFumaPa"
                            value={historialFecha.antecedentesPatologicos.antpaFumaPa}
                            // onChange={(e) => { setFuma(!fuma); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {fuma ?
                    <Slide direction="left" in={fuma} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaFumaPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaFumaPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaFumaPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Hipertensión (presión alta)--------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Hipertensión (presión alta)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaHipertensionPa"
                            value={historialFecha.antecedentesPatologicos.antpaHipertensionPa}
                            // onChange={(e) => { setHip(!hip); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {hip ?
                    <Slide direction="left" in={hip} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaHipertensionPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaHipertensionPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaHipertensionPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Toma --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Toma bebidas alcohólicas</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaBebidasAlcoholicasPa"
                            value={historialFecha.antecedentesPatologicos.antpaBebidasAlcoholicasPa}
                            // onChange={(e) => { setAlcohol(!alcohol); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {alcohol ?
                    <Slide direction="left" in={alcohol} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaBebidasAlcoholicasPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaBebidasAlcoholicasPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaBebidasAlcoholicasPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Varices --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Várices</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="historialFecha.antecedentesPatologicos,antpaVaricesPa"
                            value={historialFecha.antecedentesPatologicos.antpaVaricesPa}
                            // onChange={(e) => { setVarices(!varices); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {varices ?
                    <Slide direction="left" in={varices} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaVaricesPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaVaricesPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaVaricesPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Sarampion --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Sarampión</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="historialFecha.antecedentesPatologicos,antpaSarampionPa"
                            value={historialFecha.antecedentesPatologicos.antpaSarampionPa}
                            // onChange={(e) => { setSarampion(!sarampion); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {sarampion ?
                    <Slide direction="left" in={sarampion} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaSarampionPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaSarampionPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaSarampionPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Varicela --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Varicela</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaVaricelaPa"
                            value={historialFecha.antecedentesPatologicos.antpaVaricelaPa}
                            // onChange={(e) => { setVaricela(!varicela); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {varicela ?
                    <Slide direction="left" in={varicela} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaVaricelaPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaVaricelaPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaVaricelaPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Rubeola --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Rubeóla</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaRubeolaPa"
                            value={historialFecha.antecedentesPatologicos.antpaRubeolaPa}
                            // onChange={(e) => { setRubeola(!rubeola); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {rubeola ?
                    <Slide direction="left" in={rubeola} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaRubeolaPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaRubeolaPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaRubeolaPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Herpes --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Herpes</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="historialFecha.antecedentesPatologicos,antpaHerpesPa"
                            value={historialFecha.antecedentesPatologicos.antpaHerpesPa}
                            // onChange={(e) => { setHerpes(!herpes); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {herpes ?
                    <Slide direction="left" in={herpes} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaHerpesPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaHerpesPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaHerpesPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Paperas --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Parotiditis (paperas)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="historialFecha.antecedentesPatologicos,antpaParotiditisPa"
                            value={historialFecha.antecedentesPatologicos.antpaParotiditisPa}
                            // onChange={(e) => { setPaperas(!paperas); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {paperas ?
                    <Slide direction="left" in={paperas} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaParotiditisPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaParotiditisPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaParotiditisPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Hepatitis --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Hepatitis</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaHepatitisPa"
                            value={historialFecha.antecedentesPatologicos.antpaHepatitisPa}
                            // onChange={(e) => { setHepatitis(!hepatitis); handleChangeClinico(e)  }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {hepatitis ?
                    <Slide direction="left" in={hepatitis} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaHepatitisPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaHepatitisPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaHepatitisPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Transfusiones --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Transfusiones</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                             name="historialFecha.antecedentesPatologicos,antpaTransfusionesPa"
                            value={historialFecha.antecedentesPatologicos.antpaTransfusionesPa}
                            // onChange={(e) => { setTransfusiones(!transfusiones); handleChangeClinico(e)  }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {transfusiones ?
                    <Slide direction="left" in={transfusiones} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaTransfusionesPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaTransfusionesPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaTransfusionesPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Gastritis --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Gastritis</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaGastritisPa"
                            value={historialFecha.antecedentesPatologicos.antpaGastritisPa}
                            // onChange={(e) => { setGastritis(!gastritis) ; handleChangeClinico(e)}}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {gastritis ?
                    <Slide direction="left" in={gastritis} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaGastritisPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaGastritisPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaGastritisPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Colitis --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Colitis</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaColitisPa"
                            value={historialFecha.antecedentesPatologicos.antpaColitisPa}
                            // onChange={(e) => { setColitis(!colitis); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {colitis ?
                    <Slide direction="left" in={colitis} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaColitisPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaColitisPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaColitisPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------hernias --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Hernias</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaHerniasPa"
                            value={historialFecha.antecedentesPatologicos.antpaHerniasPa}
                            // onChange={(e) => { setHernias(!hernias); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {hernias ?
                    <Slide direction="left" in={hernias} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaHerniasPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaHerniasPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaHerniasPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>

            {/*------------ Auto --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Autoinmunes</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaAutoinmunesPa"
                            value={historialFecha.antecedentesPatologicos.antpaAutoinmunesPa}
                            // onChange={(e) => { setAutoinmunes(!autoinmunes); handleChangeClinico(e)  }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {autoinmunes ?
                    <Slide direction="left" in={autoinmunes} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaAutoinmunesPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaAutoinmunesPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaAutoinmunesPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Limitacion --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Limitación de movilidad </FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaLimitacionMovilidadPa"
                            value={historialFecha.antecedentesPatologicos.antpaLimitacionMovilidadPa}
                            // onChange={(e) => { setMovilidad(!movilidad); handleChangeClinico(e)  }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {movilidad ?
                    <Slide direction="left" in={movilidad} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaLimitacionMovilidadPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaLimitacionMovilidadPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaLimitacionMovilidadPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Problemas al nacimiento  --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Problemas al nacimiento  </FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaProblemasNacimientoPa"
                            value={historialFecha.antecedentesPatologicos.antpaProblemasNacimientoPa}
                            // onChange={(e) => { setNacimiento(!nacimiento); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {nacimiento ?
                    <Slide direction="left" in={nacimiento} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaProblemasNacimientoPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaProblemasNacimientoPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaProblemasNacimientoPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------medicamento  --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Consume algún medicamento</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                           name="historialFecha.antecedentesPatologicos,antpaConsumeMedicamentoPa"
                            value={historialFecha.antecedentesPatologicos.antpaConsumeMedicamentoPa}
                            // onChange={(e) => { setMedicamento(!medicamento) ; handleChangeClinico(e)}}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {medicamento ?
                    <Slide direction="left" in={medicamento} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaConsumeMedicamentoPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaConsumeMedicamentoPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaConsumeMedicamentoPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Depresion  --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Ha tenido depresión o ansiedad</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaDepresionAnsiedadPa"
                            value={historialFecha.antecedentesPatologicos.antpaDepresionAnsiedadPa}
                            // onChange={(e) => { setDepresion(!depresion); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {depresion ?
                    <Slide direction="left" in={depresion} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaDepresionAnsiedadPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaDepresionAnsiedadPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaDepresionAnsiedadPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Terapia  --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Ha recibido algún tipo de terapia</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaTerapiaPa"
                            value={historialFecha.antecedentesPatologicos.antpaTerapiaPa}
                            // onChange={(e) => { setTerapia(!terapia); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {terapia ?
                    <Slide direction="left" in={terapia} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaTerapiaPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaTerapiaPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaTerapiaPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
             {/*------------Otros  --------------- */}
             <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Otras</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="historialFecha.antecedentesPatologicos,antpaOtrasPa"
                            value={historialFecha.antecedentesPatologicos.antpaOtrasPa}
                            // onChange={(e) => { setOtras(!otras); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {otras ?
                    <Slide direction="left" in={otras} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaOtrasPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaOtrasPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaOtrasPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------Señas  --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="Senas" style={{ marginRight: "10px", width: "30%" }}>Señas particulares </FormLabel>
                        <span id="Senas" style={{ fontSize: "0.6em", fontStyle: "italic" }}> (tatuajes, perforaciones, cicatrices o marcas de nacimiento)</span>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="Senas"
                           name="historialFecha.antecedentesPatologicos,antpaSeniasParticularesPa"
                            value={historialFecha.antecedentesPatologicos.antpaSeniasParticularesPa}
                            // onChange={(e) => { setSenas(!senas); handleChangeClinico(e) }}
                            defaultValue="No"
                        >
                            <FormControlLabel disabled  value="No" control={<Radio />} label="No" />
                            <FormControlLabel disabled  value="Si" control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {senas ?
                    <Slide direction="left" in={senas} mountOnEnter unmountOnExit>
                        <TextField
                            disabled
                            fullWidth
                            id="antpaSeniasParticularesPaCom"
                            label="Comentarios (solo por médico)"
                            name="historialFecha.antecedentesPatologicos,antpaSeniasParticularesPaCom"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={historialFecha.antecedentesPatologicos.antpaSeniasParticularesPaCom}
                            // value={pHijos}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
        </Grid>
    )
}

export default PatologicosDF