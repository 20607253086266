//importar librerias
import * as React from 'react';
import { useEffect } from 'react'
import {
    Box,
    Typography,
    Button,
} from '@mui/material';

//importar funciones
import useAuth from '../../../hooks/useAuth';
import useHistorialClinico from '../../../hooks/useHistorialClinico';
import { useState } from 'react';

//Stepper
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';

//Preguntas
import FichaIdentificacion from './FichaIdentificacion';
import HeredoFamiliares from './Heredofamiliares';
import NoPatologicos from './NoPatologicos';
import Patologicos from './Patologicos';

const steps = ['Ficha de Identificación', 'Antecedentes Heredofamiliares', 'Antecedentes personales no patológicos', 'Antecedentes personales patológicos'];


export default function StepperClinico() {
    const { validarToken, setVentana } = useAuth()
    const { datos, guardarHistorialPaciente} = useHistorialClinico();
    useEffect(() => {
        validarToken()
        setVentana('/modulos')
        // eslint-disable-next-line
    }, [])

    //Stepper
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 10;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

        if (activeStep === steps.length - 1) {
            guardarHistorialPaciente();
            // console.log(datos)
        }
    };



    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };



    const Componentes = () => {

        switch (activeStep + 1) {
            case 1:
                return <FichaIdentificacion/>
                break;
            case 2:
                return <HeredoFamiliares />
                break;
            case 3:
                return <NoPatologicos />
                break;
            case 4:
                return <Patologicos />
                break
        }

    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1, pt: 10, textAlign: "center" }}>
                        Has terminado tu historial médico, serás redirigido en un momento.
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* <Button onClick={handleReset}>Reset</Button> */}
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1, mt: 6 }}>
                        {Componentes()}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mb: 6 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Atras
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}

                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    )

}