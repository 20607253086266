import { forwardRef, useEffect, useState } from 'react'

import {
    Radio,
    RadioGroup,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    InputLabel,
    Input,
    TextField,
    Slide
} from '@mui/material';
import useHistorialClinico from '../../../hooks/useHistorialClinico';


const HeredoFamiliares = () => {

    const { datos, handleChangeClinico} = useHistorialClinico()
    const { antHerFam } = datos
    const [diab, setDiab] = useState(antHerFam.antherDiabetes?true:false);
    const [hip, setHip] = useState(antHerFam.antherHipertencion?true:false);
    const [can, setCan] = useState(antHerFam.antherCancer?true:false);
    const [cor, setCor] = useState(antHerFam.antherEnfermedadCorazon?true:false);
    const [rin, setRin] = useState(antHerFam.antherEnfermedadRinon?true:false);
    const [asm, setAsm] = useState(antHerFam.antherAsmaP?true:false);
    const [neu, setNeu] = useState(antHerFam.antherNeurodegenerativas?true:false);
    const [auto, setAuto] = useState(antHerFam.antherAutoinmunes?true:false);
    const [disc, setDisc] = useState(antHerFam.antherDiscAuditvaVisual?true:false);
    const [conv, setConv] = useState(antHerFam.antherConvulsiones?true:false);

    const validarDataHF = ()=>{

    }   

    useEffect(()=>{
        console.log(datos)
    }, [antHerFam])
    
    return (

        <Grid container spacing={2} p={1} style={{ backgroundColor:"#FFF" }}>
            {/*------------ Diabetes--------------- */}

            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="antherDiabetes" style={{ marginRight: "10px", width: "30%" }}>Diabetes (azúcar elevada)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="antherDiabetes"
                            name="antHerFam,antherDiabetes"
                            onChange={(e) => { setDiab(!diab); handleChangeClinico(e) }}
                            value={antHerFam.antherDiabetes}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                            <FormControlLabel value={1} control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            <Grid item lg={7} sm={7} xs={12}>
                {diab ?
                    <Slide direction="left" in={diab} mountOnEnter unmountOnExit>
                        <TextField
                            fullWidth
                            id="antherDiabetesQuien"
                            label="¿Quien?"
                            name="antHerFam,antherDiabetesQuien"
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={antHerFam.antherDiabetesQuien}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Hipertensión (presión alta)--------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Hipertensión (presión alta)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antHerFam,antherHipertencion"
                            onChange={(e) => { setHip(!hip); handleChangeClinico(e) }}
                            value={antHerFam.antherHipertencion}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                            <FormControlLabel value={1} control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {hip ?
                    <Slide direction="left" in={hip} mountOnEnter unmountOnExit>
                        <TextField
                            fullWidth
                            id="antherHipertencionQuien"
                            label="¿Quien?"
                            name="antHerFam,antherHipertencionQuien"
                            value={antHerFam.antherHipertencionQuien}
                            onChange={(e) => { handleChangeClinico(e) }}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Cancer --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Cáncer</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antHerFam,antherCancer"
                            onChange={(e) => { setCan(!can); handleChangeClinico(e) }}
                            value={antHerFam.antherCancer}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                            <FormControlLabel value={1} control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {can ?
                    <Slide direction="left" in={can} mountOnEnter unmountOnExit>
                        <TextField
                            fullWidth
                            id="antherCancerQuien"
                            label="¿Quien?"
                            name="antHerFam,antherCancerQuien"
                            value={antHerFam.antherCancerQuien}
                            onChange={(e) => { handleChangeClinico(e) }}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Corazon --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Enfermedad del corazón</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antHerFam,antherEnfermedadCorazon"
                            onChange={(e) => { setCor(!cor); handleChangeClinico(e) }}
                            value={antHerFam.antherEnfermedadCorazon}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                            <FormControlLabel value={1} control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {cor ?
                    <Slide direction="left" in={cor} mountOnEnter unmountOnExit>
                        <TextField
                            fullWidth
                            id="antherEnfermedadCorazonQuien"
                            label="¿Quien?"
                            name="antHerFam,antherEnfermedadCorazonQuien"
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={antHerFam.antherEnfermedadCorazonQuien}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Riñon --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Enfermedad de riñón</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antHerFam,antherEnfermedadRinon"
                            onChange={(e) => { setRin(!rin); handleChangeClinico(e)}}
                            value={antHerFam.antherEnfermedadRinon}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                            <FormControlLabel value={1} control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {rin ?
                    <Slide direction="left" in={rin} mountOnEnter unmountOnExit>
                        <TextField
                            fullWidth
                            id="medicoTitulo"
                            label="¿Quien?"
                            name="antHerFam,antherEnfermedadRinonQuien"
                            onChange={(e) => {handleChangeClinico(e) }}
                            value={antHerFam.antherEnfermedadRinonQuien}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Asma --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Asma (silba el pecho)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antHerFam,antherAsmaP"
                            onChange={(e) => { setAsm(!asm); handleChangeClinico(e) }}
                            value={antHerFam.antherAsmaP}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                            <FormControlLabel value={1} control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {asm ?
                    <Slide direction="left" in={asm} mountOnEnter unmountOnExit>
                        <TextField
                            fullWidth
                            id="antherAsmaQuien"
                            label="¿Quien?"
                            name="antHerFam,antherAsmaQuien"
                            onChange={(e) => {handleChangeClinico(e) }}
                            value={antHerFam.antherAsmaQuien}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Neuro --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Neurodegenerativas (Depresión, Demencia,Parkinson, Alzheimer)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antHerFam,antherNeurodegenerativas"
                            onChange={(e) => { setNeu(!neu); handleChangeClinico(e) }}
                            value={antHerFam.antherNeurodegenerativas}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                            <FormControlLabel value={1} control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {neu ?
                    <Slide direction="left" in={neu} mountOnEnter unmountOnExit>
                        <TextField
                            fullWidth
                            id="antherNeurodegenerativasQuien"
                            label="¿Quien?"
                            name="antHerFam,antherNeurodegenerativasQuien"
                            onChange={(e) => {handleChangeClinico(e) }}
                            value={antHerFam.antherNeurodegenerativasQuien}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Auto --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Autoinmunes (LUPUS, Artritis, Enf Tiroidea)</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antHerFam,antherAutoinmunes"
                            onChange={(e) => { setAuto(!auto) ; handleChangeClinico(e)}}
                            value={antHerFam.antherAutoinmunes}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                            <FormControlLabel value={1} control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {auto ?
                    <Slide direction="left" in={auto} mountOnEnter unmountOnExit>
                        <TextField
                            fullWidth
                            id="antherAutoinmunesQuien"
                            label="¿Quien?"
                            name="antHerFam,antherAutoinmunesQuien"
                            onChange={(e) => {handleChangeClinico(e) }}
                            value={antHerFam.antherAutoinmunesQuien}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Discapacidad --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Discapacidad auditiva o visual</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antHerFam,antherDiscAuditvaVisual"
                            onChange={(e) => { setDisc(!disc); handleChangeClinico(e) }}
                            value={antHerFam.antherDiscAuditvaVisual}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                            <FormControlLabel value={1} control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {disc ?
                    <Slide direction="left" in={disc} mountOnEnter unmountOnExit>
                        <TextField
                            fullWidth
                            id="antherDiscAuditvaVisualQuien"
                            label="¿Quien?"
                            name="antHerFam,antherDiscAuditvaVisualQuien"
                            onChange={(e) => {handleChangeClinico(e) }}
                            value={antHerFam.antherDiscAuditvaVisualQuien}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
            {/*------------ Convulsiones --------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={8} sm={8} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Convulsiones</FormLabel>
                    </Grid>
                    <Grid lg={4} sm={4} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antHerFam,antherConvulsiones"
                            onChange={(e) => { setConv(!conv) ; handleChangeClinico(e)}}
                            value={antHerFam.antherConvulsiones}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                            <FormControlLabel value={1} control={<Radio />} label="Si" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                {conv ?
                    <Slide direction="left" in={conv} mountOnEnter unmountOnExit>
                        <TextField
                            fullWidth
                            id="antherConvulsionesQuien"
                            label="¿Quien?"
                            name="antHerFam,antherConvulsionesQuien"
                            onChange={(e) => {handleChangeClinico(e) }}
                            value={antHerFam.antherConvulsionesQuien}
                            variant="standard"
                            autoComplete="family-name"
                        />
                    </Slide>
                    : null}
            </Grid>
        </Grid>
    )
}

export default HeredoFamiliares