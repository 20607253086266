import { forwardRef, useEffect, useState } from 'react'

import {
    Grid,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import useHistorialClinico from '../../../hooks/useHistorialClinico';


const AntecedentesLaborales = () => {

    const { datosMedico,
        dataUser,
        handleChangeClinicoMed,
        laborales,
        setLaborales,
        antecedentesLaborales,
        setAntecedentesLaborales } = useHistorialClinico()
    const { historialclinico } = datosMedico;

    const handleLaboral = (e) => {
        console.log(e.target.value)
        console.log(e.target.name)

        const newData = { ...laborales };
        newData[e.target.name] = e.target.value;
        setLaborales(newData)
    }

    const agregarAntecedente = () => {
        console.log(antecedentesLaborales)
        const arr = [...antecedentesLaborales];
        console.log(arr)
        arr.push(laborales);
        setAntecedentesLaborales(arr)

        setLaborales({
            ...laborales,
            antlId: 0,//dejar por default
            antlGuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",//dejar por default
            antlUsuarioFk: 0,//dejar por default
            antlEmpresa: "",
            antlActividad: "",
            antlDuracion: "",
            antlAgentesExposicion: "",
            antlDuracionExposicion: "",
            antlAntecedentesEnfProfesionales: "",
            antlIncapacidades: "",
            antlHcFk: 0
        })

    }
    
    const columns = [
        { id: 'antlEmpresa', label: 'Empresa' },
        { id: 'antlActividad', label: 'Actividad' },
        { id: 'antlDuracion', label: 'Duración' },
        { id: 'antlAgentesExposicion', label: 'Agentes de Exposición' },
        { id: 'antlDuracionExposicion', label: 'Duración de Exposición' },
        { id: 'antlAntecedentesEnfProfesionales', label: 'Accidentes o enf. Profesionaless' },
        { id: 'antlIncapacidades', label: 'Incapacidades' }

    ];

    return (
        <>
            <Grid container spacing={2} p={1} style={{ backgroundColor:"#FFF" }}>
                <Grid item lg={4} sm={4} xs={12}>
                    <TextField

                        name="antlAnio"
                        onChange={(e) => { handleLaboral(e) }}
                        value={laborales.antlAnio}
                        fullWidth
                        id="inicio"
                        // defaultValue={historialclinico.}
                        label="Edad de inicio de vida laboral"
                        type='number'
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={2} sm={2} xs={12}>

                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <TextField

                        name="antlAntiguedad"
                        onChange={(e) => { handleLaboral(e) }}
                        value={laborales.antlAntiguedad}
                        fullWidth
                        id="antlAntiguedad"
                        // defaultValue={historialclinico.}
                        label="Antigüedad en la empresa y el puesto"
                        autoFocus
                        variant="standard"
                    />
                    <span id="antS" style={{ fontSize: "0.6em", fontStyle: "italic" }}> solo exámenes periodicos</span>

                </Grid>
                <Grid item lg={12} sm={12} xs={12} mt={4} style={{ textAlign: "center" }}>
                    Agregar antecedente laboral
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField

                        name="antlEmpresa"
                        onChange={(e) => { handleLaboral(e) }}
                        value={laborales.antlEmpresa}
                        fullWidth
                        id="antlEmpresa"
                        // defaultValue={historialclinico.}
                        label="Empresa"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField

                        name="antlActividad"
                        onChange={(e) => { handleLaboral(e) }}
                        value={laborales.antlActividad}
                        fullWidth
                        id="antlActividad"
                        // defaultValue={historialclinico.}
                        label="Actividad"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField

                        name="antlDuracion"
                        onChange={(e) => { handleLaboral(e) }}
                        value={laborales.antlDuracion}
                        fullWidth
                        id="antlDuracion"
                        // defaultValue={historialclinico.}
                        label="Duración"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField

                        name="antlAgentesExposicion"
                        onChange={(e) => { handleLaboral(e) }}
                        value={laborales.antlAgentesExposicion}
                        fullWidth
                        id="antlAgentesExposicion"
                        // defaultValue={historialclinico.}
                        label="Agentes de exposición (ruido, vibraciones, químicos…)"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField

                        name="antlDuracionExposicion"
                        onChange={(e) => { handleLaboral(e) }}
                        value={laborales.antlDuracionExposicion}
                        fullWidth
                        id="antlDuracionExposicion"
                        // defaultValue={historialclinico.}
                        label="Duración de la exposición (hrs al día)"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField

                        name="antlAntecedentesEnfProfesionales"
                        onChange={(e) => { handleLaboral(e) }}
                        value={laborales.antlAntecedentesEnfProfesionales}
                        fullWidth
                        id="antlAntecedentesEnfProfesionales"
                        // defaultValue={historialclinico.}
                        label="Accidentes o enf. Profesionales"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField

                        name="antlIncapacidades"
                        onChange={(e) => { handleLaboral(e) }}
                        value={laborales.antlIncapacidades}
                        fullWidth
                        id="antlIncapacidades"
                        // defaultValue={historialclinico.}
                        label="Incapacidades"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <Button onClick={() => agregarAntecedente()} variant='contained'>Agregar Antecedente</Button>
                </Grid>
                {/* Tabla */}
                <Grid item lg={12} sm={12} xs={12}>
                    <TableContainer >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align='center'
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {antecedentesLaborales
                                    .map((row) => {
                                        return (
                                            <TableRow
                                                key={row.userGuid}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">{row.antlEmpresa}</TableCell>
                                                <TableCell align="center">{row.antlActividad}</TableCell>
                                                <TableCell align="center">{row.antlDuracion}</TableCell>
                                                <TableCell align="center">{row.antlAgentesExposicion}</TableCell>
                                                <TableCell align="center">{row.antlDuracionExposicion}</TableCell>
                                                <TableCell align="center">{row.antlAntecedentesEnfProfesionales}</TableCell>
                                                <TableCell align="center">{row.antlIncapacidades}</TableCell>

                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    )
}

export default AntecedentesLaborales