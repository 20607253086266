import { createContext, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import dateformat from 'dateformat'
import axios from "axios";
import  secureLocalStorage  from  "react-secure-storage";
const HistorialContext = createContext()

const client = axios.create({
  baseURL: "https://appsexpedientemedico.azurewebsites.net/api"
});

const HistorialProvider = ({ children }) => {


  const { validarToken } = useAuth()

  const [loadTime, setLoadTime] = useState(false)
  const [datos, setDatos] = useState({
    fechaInicio: dateformat(new Date(), "yyyy-mm-dd"),
    fechaFin: dateformat(new Date(), "yyyy-mm-dd")
  })
  const { fechaInicio, fechaFin } = datos

  const [error, setError] = useState({
    band: false,
    texto: ''
  })

  const [citasUsuario, setCitasUsuario] = useState([])

  const handleChangeDatos = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value
    });
  };

  const consultarCitas = async (fechainicio, fechafin) => {
    try {
      setLoadTime(true)
      validarToken()
      let token = secureLocalStorage.getItem('tokenid');
      const data = {
        "token": token,//DEJALO VACIO
        "fi": fechainicio,
        "ff": fechafin,
        "option": 1
      }
      // const response = await client.post(`/citas/citaHistorial?token=${token}&FI=${fechainicio}T00:00:00.000Z&FF=${fechafin}T23:59:00.000Z&option=1`, {
      const response = await client.post(`/citas/citaHistorial`,data, {
      
        headers: {
          Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
          'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
          'Content-Type': 'application/json',
        }
      })
  
      if (typeof response.data.response === "string") {
        
        setCitasUsuario([]);
        setError({ band: true, texto: response.data.response })
      }else{
        setCitasUsuario(response.data.response);
      }
      //
      setLoadTime(false)

    } catch (e) {
      setLoadTime(false)
      
      setError({ band: true, texto: "No hay citas entre el 24/2/2023 y el 24/2/2023" })
    }
  }
  
  const cancelarCita = async (cguidCita) => {
    try {
      setLoadTime(true)
      validarToken()
      let token = secureLocalStorage.getItem('tokenid');
      const dataCancel ={
        "guidDate": cguidCita
      }
      const response = await client.post(`/citas/cancelDate`, dataCancel, {
        headers: {
          Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
          'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
          'Content-Type': 'application/json',
        }
      })
      const data = {
        "token": token,//DEJALO VACIO
        "fi": fechaInicio,
        "ff": fechaFin,
        "option": 1
      }
      // const response2 = await client.post(`/citas/citaHistorial?token=${token}&FI=${fechaInicio}T00:00:00.000Z&FF=${fechaFin}T23:59:00.000Z&option=1`, {
      const response2 = await client.post(`/citas/citaHistorial`,data, {
        headers: {
          Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
          'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
          'Content-Type': 'application/json',
        }
      })

      if (typeof response2.data.response === "string") {
        
        setCitasUsuario([]);
        setError({ band: true, texto: response2.data.response })
      }else{
        setCitasUsuario(response2.data.response);
      }
      if (typeof response.data.response === "string") {
                
        setError({ band: true, texto: response.data.response })
      }
      //
      setLoadTime(false)

    } catch (e) {
      setLoadTime(false)
    }
  }
  const confirmarCita = async (cguidCita) => {
    try {
      setLoadTime(true)
      validarToken()
      let token = secureLocalStorage.getItem('tokenid');
      const dataConfirm ={
        "guidDate": cguidCita
      }
      const response = await client.post(`/citas/confirmacionCitaApp`, dataConfirm, {
        headers: {
          Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
          'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
          'Content-Type': 'application/json',
        }
      })
      const data = {
        "token": token,//DEJALO VACIO
        "fi": fechaInicio,
        "ff": fechaFin,
        "option": 1
      }
      // const response2 = await client.post(`/citas/citaHistorial?token=${token}&FI=${fechaInicio}T00:00:00.000Z&FF=${fechaFin}T23:59:00.000Z&option=1`, {
      const response2 = await client.post(`/citas/citaHistorial`, data, {
        headers: {
          Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
          'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
          'Content-Type': 'application/json',
        }
      })

      if (typeof response2.data.response === "string") {
        
        setCitasUsuario([]);
        setError({ band: true, texto: response2.data.response })
      }else{
        setCitasUsuario(response2.data.response);
      }
      if (typeof response.data.response === "string") {
                
        setError({ band: true, texto: response.data.response })
      }
      //
      setLoadTime(false)

    } catch (e) {
      setLoadTime(false)
    }
  }

  useEffect(() => {
    consultarCitas(fechaInicio, fechaFin)
  }, [])

  return (
    <HistorialContext.Provider
      value={{
        citasUsuario,
        handleChangeDatos,
        datos,
        setError,
        error,
        consultarCitas,
        loadTime,
        cancelarCita,
        confirmarCita
      }}
    >
      {children}
    </HistorialContext.Provider>
  )
}

export {
  HistorialProvider
}

export default HistorialContext