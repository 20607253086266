import { forwardRef, useEffect, useState } from 'react'

import {
    Radio,
    RadioGroup,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    InputLabel,
    Input,
    TextField,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import useHistorialClinico from '../../../hooks/useHistorialClinico';


const GinecoObstetricosD = () => {

    const { historialAnio, dataUser, handleChangeClinicoMed} = useHistorialClinico()
    const { historialclinico } = historialAnio;
    // const {
    //     pLugarNacimiento,
    //     pEscolaridad,
    //     pEstadoCivil,
    //     pHijos,
    //     pTelEmergencia
    // } = datos

    return (
        <>
        <Grid container spacing={2} p={1} style={{backgroundColor:"#FFF"}}>
            <Grid item lg={3} sm={3} xs={12}>
                <TextField
                    
                    name="historialclinico,hcAniosGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcAniosGo}
                    fullWidth
                    disabled
                    id="hcAniosGo"
                    // defaultValue={historialclinico.}
                    label="Menarca (Años)"
                    autoFocus
                    type='number'
                    variant="standard"
                />
            </Grid>
            <Grid item lg={3} sm={3} xs={12}>
                <TextField
                    
                    name="historialclinico,hcRitmoGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcRitmoGo}
                    fullWidth
                    disabled
                    id="hcRitmoGo"
                    // defaultValue={historialclinico.}
                    label="Ritmo"
                    autoFocus
                    variant="standard"
                />
            </Grid>
            <Grid item lg={2} sm={2} xs={12}>
                <TextField
                    
                    name="historialclinico,hcFumGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcFumGo}
                    fullWidth
                    disabled
                    id="Fum"
                    // defaultValue={historialclinico.}
                    label="FUM"
                    autoFocus
                    variant="standard"
                />
            </Grid>
            <Grid item lg={2} sm={2} xs={12}>
                <TextField
                    
                    name="historialclinico,hcDismenorreaGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcDismenorreaGo}
                    fullWidth
                    disabled
                    id="Dismenorrea"
                    // defaultValue={historialclinico.}
                    label="Dismenorrea"
                    autoFocus
                    variant="standard"
                />
            </Grid>
            <Grid item lg={1} sm={1} xs={12}>
                <TextField
                    
                    name="historialclinico,hcGGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcGGo}
                    fullWidth
                    disabled
                    id="hcGGo"
                    // defaultValue={historialclinico.}
                    label="G"
                    autoFocus
                    variant="standard"
                />
            </Grid>
            <Grid item lg={1} sm={1} xs={12}>
                <TextField
                    
                    name="historialclinico,hcPGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcPGo}
                    fullWidth
                    disabled
                    id="hcPGo"
                    // defaultValue={historialclinico.}
                    label="P"
                    autoFocus
                    variant="standard"
                />
            </Grid>
            <Grid item lg={2} sm={2} xs={12}>
                <TextField
                    
                    name="historialclinico,hcAGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcAGo}
                    fullWidth
                    disabled
                    id="hcAGo"
                    // defaultValue={historialclinico.}
                    label="A"
                    autoFocus
                    variant="standard"
                />
            </Grid>
            <Grid item lg={2} sm={2} xs={12}>
                <TextField
                    
                    name="historialclinico,hcCGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcCGo}
                    fullWidth
                    disabled
                    id="hcCGo"
                    // defaultValue={historialclinico.}
                    label="C"
                    autoFocus
                    variant="standard"
                />                
            </Grid>
            <Grid item lg={2} sm={2} xs={12}>
                <TextField
                    
                    name="historialclinico,hcFupocGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcFupocGo}
                    fullWidth
                    disabled
                    id="FUPoC"
                    // defaultValue={historialclinico.}
                    label="FUPoC"
                    autoFocus
                    variant="standard"
                />
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
                <TextField
                    
                    name="historialclinico,hcMpfGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcMpfGo}
                    fullWidth
                    disabled
                    id="MPF"
                    // defaultValue={historialclinico.}
                    label="MPF"
                    autoFocus
                    variant="standard"
                />
            </Grid>
            <Grid item lg={2} sm={2} xs={12}>
                DOC
            </Grid>
            <Grid item lg={2} sm={2} xs={12}>
                {/* <TextField
                    
                    name="historialclinico,hcDocFechaGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcDocFechaGo}
                    fullWidth
                    disabled
                    id="hcDocFechaGo"
                    // defaultValue={historialclinico.}
                    label="Fecha"
                    autoFocus
                    variant="standard"
                /> */}
                <TextField
                    id="hcDocFechaGo"
                    name="historialclinico,hcDocFechaGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcDocFechaGo}
                    label="Fecha"
                    type="date"
                    variant="standard"
                    fullWidth
                    disabled
                    // onChange={handleChangedataAvance.
                    InputLabelProps={{
                        shrink: true,
                    }
                    } />
            </Grid>
            <Grid item lg={3} sm={3} xs={12}>
                <TextField
                    
                    name="historialclinico,hcDocResultGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcDocResultGo}
                    fullWidth
                    disabled
                    id="hcDocResultGo"
                    // defaultValue={historialclinico.}
                    label="Resultado"
                    autoFocus
                    variant="standard"
                />
            </Grid>
            <Grid item lg={1} sm={1} xs={12}>
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    
                    name="historialclinico,hcDocmaGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcDocmaGo}
                    fullWidth
                    disabled
                    id="hcDocmaGo"
                    // defaultValue={historialclinico.}
                    label="DocMa"
                    autoFocus
                    variant="standard"
                />
                <span id="DocMaS" style={{ fontSize: "0.6em", fontStyle: "italic" }}> Interrogar a partir de 40 años </span>

            </Grid>
            <Grid item lg={2} sm={2} xs={12}>
            Antígeno Prostático 
            </Grid>
            <Grid item lg={2} sm={2} xs={12}>
                {/* <TextField
                    
                    name="historialclinico,hcAntigenoFechaGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcAntigenoFechaGo}
                    fullWidth
                    disabled
                    id="hcAntigenoFechaGo"
                    // defaultValue={historialclinico.}
                    label="Fecha"
                    autoFocus
                    variant="standard"
                /> */}
                <TextField
                    id="hcAntigenoFechaGo"
                    name="historialclinico,hcAntigenoFechaGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcAntigenoFechaGo}
                    label="Fecha"
                    type="date"
                    variant="standard"
                    fullWidth
                    disabled
                    // onChange={handleChangedataAvance.
                    InputLabelProps={{
                        shrink: true,
                    }
                    } />
            </Grid>
            <Grid item lg={3} sm={3} xs={12}>
                <TextField
                    
                    name="historialclinico,hcAntigenoResultGo"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={historialclinico.hcAntigenoResultGo}
                    fullWidth
                    disabled
                    id="hcAntigenoResultGo"
                    // defaultValue={historialclinico.}
                    label="Resultado"
                    autoFocus
                    variant="standard"
                />
            </Grid>
        </Grid>
       </> 
    )
}

export default GinecoObstetricosD