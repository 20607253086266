import { forwardRef, useEffect, useState } from 'react'

import {
    Radio,
    RadioGroup,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    FormGroup,
    InputLabel,
    Input,
    TextField,
    Checkbox,
    Typography
} from '@mui/material';
import useHistorialClinico from '../../../hooks/useHistorialClinico';


const NoPatologicos = () => {

    const { datos, handleChangeClinico, handleChangeServicios } = useHistorialClinico()
    const { antNoPatologicos } = datos
    const [otVac, setOtVac] = useState(false);
    const [servicios, setServicios] = useState([])
    const [vacunas, setVacunas] = useState([])

    useEffect(() => {
        console.log("No patologicos servicios vacunas")
        setServicios(datos.antNoPatologicos.antnopaServicios);
        setVacunas(datos.antNoPatologicos.antnopaVacunacion);
    }, [datos])

    const handleServicios = (e) => {
        var arr = [];
        for (var i = 0; i < servicios.length; i++) {
            arr.push(servicios[i]);
        }
        if (arr.includes(e)) {
            arr.splice(arr.indexOf(e), 1);
        } else {
            arr.push(e)
        }
        setServicios(arr);
        handleChangeServicios(arr, "antnopaServicios");
    }

    const handleVacunas = (e) => {
        var arr = [];
        for (var i = 0; i < vacunas.length; i++) {
            arr.push(vacunas[i]);
        }
        if (arr.includes(e)) {
            arr.splice(arr.indexOf(e), 1);
        } else {
            arr.push(e)
        }
        setVacunas(arr);
        handleChangeServicios(arr, "antnopaVacunacion");
    }

    return (

        <Grid container spacing={2} p={1} style={{ backgroundColor:"#FFF" }}>
            {/*------------ Casa & material--------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={2} sm={2} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Casa:</FormLabel>
                    </Grid>
                    <Grid lg={10} sm={10} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaCasaTipo"
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={antNoPatologicos.antnopaCasaTipo != "" ? antNoPatologicos.antnopaCasaTipo : "Propia"}
                        >
                            <FormControlLabel value="Propia" control={<Radio />} label="Propia" />
                            <FormControlLabel value="Rentada" control={<Radio />} label="Rentada" />
                            <FormControlLabel value="Prestada" control={<Radio />} label="Prestada" />

                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                <TextField
                    fullWidth
                    id="antnopaCasaMaterial"
                    label="Material de Construcción"
                    name="antNoPatologicos,antnopaCasaMaterial"
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={antNoPatologicos.antnopaCasaMaterial}
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>
            {/*------------ Cuartos & mascotas--------------- */}
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    fullWidth
                    id="antnopaCuartos"
                    label="Cuartos"
                    name="antNoPatologicos,antnopaCuartos"
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={antNoPatologicos.antnopaCuartos}
                    type='number'
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    fullWidth
                    id="antnopaPersonasCuarto"
                    label="Personas por cuarto"
                    name="antNoPatologicos,antnopaPersonasCuarto"
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={antNoPatologicos.antnopaPersonasCuarto}
                    type='number'
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    fullWidth
                    id="antnopaMascotas"
                    label="Mascotas"
                    name="antNoPatologicos,antnopaMascotas"
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={antNoPatologicos.antnopaMascotas}
                    // value={pHijos}
                    variant="standard"

                />
                <span id="Mascotas" style={{ fontSize: "0.6em", fontStyle: "italic" }}>Anotar tipo y cantidad.</span>

            </Grid>

            {/*------------ Servicios--------------- */}
            {servicios != undefined ?
                <>
                    <Grid lg={12} sm={12} xs={12} mt={2}>
                        <FormLabel id="checks" style={{ marginRight: "10px", width: "30%" }}>La casa cuenta con:</FormLabel>
                    </Grid>
                    <Grid lg={12} sm={12} xs={12} >

                        <FormGroup style={{ display: "contents" }} aria-labelledby="checks" onChange={(e) => { handleServicios(e.target.value) }}>
                            <FormControlLabel style={{ width: "24%" }} value={"Agua entubada"} control={<Checkbox />} label="Agua entubada" />
                            <FormControlLabel style={{ width: "24%" }} value={"Luz eléctrica"} control={<Checkbox />} label="Luz eléctrica" />
                            <FormControlLabel style={{ width: "24%" }} value={"Gas (natural o lp)"} control={<Checkbox />} label="Gas (natural o LP)" />
                            <FormControlLabel style={{ width: "24%" }} value={"Drenaje"} control={<Checkbox />} label="Drenaje" />
                        </FormGroup>

                    </Grid>
                    <Grid lg={12} sm={12} xs={12} >
                        <FormGroup style={{ display: "contents" }} aria-labelledby="checks" onChange={(e) => { handleServicios(e.target.value) }}>
                            <FormControlLabel style={{ width: "24%" }} value={"Agua no entubada"} control={<Checkbox />} label="Agua no entubada" />
                            <FormControlLabel style={{ width: "24%" }} value={"Leña o petróleo"} control={<Checkbox />} label="Leña o petróleo" />
                            <FormControlLabel style={{ width: "24%" }} value={"Fosa séptica"} control={<Checkbox />} label="Fosa séptica" />
                            <FormControlLabel style={{ width: "24%" }} value={"Piso de tierra"} control={<Checkbox />} label="Piso de tierra" />
                        </FormGroup>
                    </Grid></>
                : null}

            {/*------------ Higiene--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={2} sm={2} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Higiene personal (baño):</FormLabel>
                    </Grid>
                    <Grid lg={10} sm={10} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaHigienePersonal"
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={antNoPatologicos.antnopaHigienePersonal != "" ? antNoPatologicos.antnopaHigienePersonal : "Diario"}
                        >
                            <FormControlLabel style={{ width: "24%" }} value="Diario" control={<Radio />} label="Diario" />
                            <FormControlLabel style={{ width: "24%" }} value="Cada 3er dia" control={<Radio />} label="Cada 3er dia" />
                            <FormControlLabel style={{ width: "24%" }} value="Semanal" control={<Radio />} label="Semanal" />
                            <FormControlLabel style={{ width: "24%" }} value="Mensual" control={<Radio />} label="Mensual" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            {/*------------ Aseo--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={2} sm={2} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Aseo bucal</FormLabel>
                    </Grid>
                    <Grid lg={10} sm={10} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaAceoBucal"
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={antNoPatologicos.antnopaAceoBucal != "" ? antNoPatologicos.antnopaAceoBucal : "Diario"}
                            defaultValue="Diario"
                        >
                            <FormControlLabel style={{ width: "24%" }} value="3 x dia" control={<Radio />} label="3 x día" />
                            <FormControlLabel style={{ width: "24%" }} value="1 o 2 x dia" control={<Radio />} label="1 o 2 x día" />
                            <FormControlLabel style={{ width: "24%" }} value="Esporádico" control={<Radio />} label="Esporádico" />
                            <FormControlLabel style={{ width: "24%" }} value="Nunca" control={<Radio />} label="Nunca" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            {/*------------ Vacunacion--------------- */}
            <Grid lg={12} sm={12} xs={12} mt={2}>
                <FormLabel id="checks" style={{ marginRight: "10px", width: "30%" }}>Vacunación:</FormLabel>
            </Grid>
            <Grid lg={12} sm={12} xs={12} style={{ textAlign: "center" }}>
                <FormGroup style={{ display: "contents" }} aria-labelledby="checks" 
                onChange={(e) => { 
                    handleVacunas(e.target.value); 
                    if(e.target.value === "Otras") 
                        setOtVac(!otVac) 
                    // else null; 
                }}
                >
                    <FormControlLabel style={{ width: "16%" }} value={"Tétanos"} control={<Checkbox />} label="Tétanos" />
                    <FormControlLabel style={{ width: "16%" }} value={"Sarampión/Rubeóla"} control={<Checkbox />} label="Sarampión/Rubeóla" />
                    <FormControlLabel style={{ width: "16%" }} value={"Hepatitis"} control={<Checkbox />} label="Hepatitis B" />
                    <FormControlLabel style={{ width: "16%" }} value={"Influenza"} control={<Checkbox />} label="Influenza" />
                    <FormControlLabel style={{ width: "12%" }} value={"Otras"} control={<Checkbox />} label="Otras" />
                    {otVac ? <TextField
                        fullWidth
                        id="medicoTitulo"
                        label="¿Cuáles?"
                        style={{ width: "20%" }}
                        name="medicoTitulo"
                        // value={pHijos}
                        variant="standard"

                    />
                        : null
                    }
                </FormGroup>
            </Grid>

            {/*------------ Ejercicio--------------- */}
            <Grid item lg={4} sm={4} xs={12} >
                <TextField
                    fullWidth
                    id="antnopaEjercicio"
                    label="Ejercicio"
                    name="antNoPatologicos,antnopaEjercicio"
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={antNoPatologicos.antnopaEjercicio}
                    // value={pHijos}
                    variant="standard"

                />
                <span id="Ejercicio" style={{ fontSize: "0.6em", fontStyle: "italic" }}>¿Cuál practica de manera regular?</span>
            </Grid>
            <Grid item lg={8} sm={8} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={2} sm={2} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Frecuencia</FormLabel>
                    </Grid>
                    <Grid lg={10} sm={10} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaDuracionActividad"
                            defaultValue={"Diario"}
                            // onChange={(e) => { handleChangeClinico(e) }}
                            // value={antNoPatologicos.antnopaDuracionActividad != "" ? antNoPatologicos.antnopaDuracionActividad : "Diario"}
                        >
                            <FormControlLabel style={{ width: "32%" }} value="Diario" control={<Radio />} label="Diario" />
                            <FormControlLabel style={{ width: "32%" }} value="2-3 veces x sem" control={<Radio />} label="2-3 veces x sem" />
                            <FormControlLabel style={{ width: "32%" }} value="1 x semana" control={<Radio />} label="1 x semana" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            {/*------------ Sangre--------------- */}
            <Grid item lg={4} sm={4} xs={12} pt={0}>
                <TextField
                    fullWidth
                    id="sanguíneo"
                    label="Tipo sanguíneo"
                    name="antNoPatologicos,antnopaTipoSangre"
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={antNoPatologicos.antnopaTipoSangre}
                    // value={pHijos}
                    variant="standard"

                />
                <span id="sanguíneo" style={{ fontSize: "0.6em", fontStyle: "italic" }}>Grupo y RH</span>
            </Grid>
            <Grid item lg={8} sm={8} xs={12} pt={0}>
                <TextField
                    fullWidth
                    id="Comentarios"
                    label="Comentarios"
                    name="Comentarios"
                    // value={pHijos}
                    variant="standard"
                    disabled
                />
            </Grid>

            {/*------------ Intensidad Ej--------------- */}
            <Grid item lg={6} sm={6} xs={12}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={3} sm={3} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Intensidad del ejercicio:</FormLabel>
                    </Grid>
                    <Grid lg={9} sm={9} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaIntencidadEjercicio"
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={antNoPatologicos.antnopaIntencidadEjercicio!=""?antNoPatologicos.antnopaIntencidadEjercicio:"Leve"}
                        >
                            <FormControlLabel value="Leve" control={<Radio />} label="Leve (no sube la frecuencia cardíaca)" />
                            <FormControlLabel value="Moderada" control={<Radio />} label="Moderada ( el ejercicio me permite hablar)" />
                            <FormControlLabel value="Elevada" control={<Radio />} label="Elevada ( no puedo hablar mientras hago ejercicio)" />
                            <FormControlLabel value="Deportista" control={<Radio />} label="Deportista de alto rendimiento" />


                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    fullWidth
                    id="Duracion"
                    label="Duración de la actividad "
                    name="antNoPatologicos,antnopaDuracionActividad"
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={antNoPatologicos.antnopaDuracionActividad}
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>

            {/*------------ Hábitos alimenticios--------------- */}
            <Grid item lg={12} sm={12} xs={12}>
                <Typography component="h1" variant="h6" >
                    Hábitos alimenticios
                </Typography>
                <Typography variant="body1">
                    ¿Cuántos dias a la semana consume los siguientes alimentos?
                </Typography>
            </Grid>
            {/*------------ -----------------Habitos/Frutas & verduras--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={4} sm={4} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Frutas y verduras:</FormLabel>
                    </Grid>
                    <Grid lg={8} sm={8} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaFrutasVerduras"
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={antNoPatologicos.antnopaFrutasVerduras!=""?antNoPatologicos.antnopaFrutasVerduras:"5-7semana"}
                        >
                            <FormControlLabel value="5-7semana" control={<Radio />} label="5 a 7 días de la semana" />
                            <FormControlLabel value="3-4semana" control={<Radio />} label="3 a 4 días de la semana " />
                            <FormControlLabel value="Ningun" control={<Radio />} label="Ningún día a la semana" />

                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            {/*------------ -----------------Habitos/Azucares--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={4} sm={4} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Azúcares refinados (azúcar, refrescos, jugos, pan y galletas):</FormLabel>
                    </Grid>
                    <Grid lg={8} sm={8} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaAzucaresRefinados"
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={antNoPatologicos.antnopaAzucaresRefinados!=""?antNoPatologicos.antnopaAzucaresRefinados:"5-7semana"}
                        >
                            <FormControlLabel value="5-7semana" control={<Radio />} label="5 a 7 días de la semana" />
                            <FormControlLabel value="3-4semana" control={<Radio />} label="3 a 4 días de la semana " />
                            <FormControlLabel value="Ningun" control={<Radio />} label="Ningún día a la semana" />

                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            {/*------------ -----------------Habitos/Agua--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={4} sm={4} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Consumo de 4 a 6 vasos de agua al día :</FormLabel>
                    </Grid>
                    <Grid lg={8} sm={8} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopa4a6vasosAgua"
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={antNoPatologicos.antnopa4a6vasosAgua!=""?antNoPatologicos.antnopa4a6vasosAgua:"5-7semana"}
                        >
                            <FormControlLabel value="5-7semana" control={<Radio />} label="5 a 7 días de la semana" />
                            <FormControlLabel value="3-4semana" control={<Radio />} label="3 a 4 días de la semana " />
                            <FormControlLabel value="Ningun" control={<Radio />} label="Ningún día a la semana" />

                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            {/*------------ -----------------Habitos/Grasas--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={4} sm={4} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Consumo de grasas saturadas ( fritos, empanizados, capeados):</FormLabel>
                    </Grid>
                    <Grid lg={8} sm={8} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaGrasasSaturadas"
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={antNoPatologicos.antnopaGrasasSaturadas!=""?antNoPatologicos.antnopaGrasasSaturadas:"5-7semana"}
                        >
                            <FormControlLabel value="5-7semana" control={<Radio />} label="5 a 7 días de la semana" />
                            <FormControlLabel value="3-4semana" control={<Radio />} label="3 a 4 días de la semana " />
                            <FormControlLabel value="Ningun" control={<Radio />} label="Ningún día a la semana" />

                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            {/*------------ -----------------Habitos/Carnes Rojas--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={4} sm={4} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Carnes rojas :</FormLabel>
                    </Grid>
                    <Grid lg={8} sm={8} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaCarnesRojas"
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={antNoPatologicos.antnopaCarnesRojas!=""?antNoPatologicos.antnopaCarnesRojas:"5-7semana"}
                        >
                            <FormControlLabel value="5-7semana" control={<Radio />} label="5 a 7 días de la semana" />
                            <FormControlLabel value="3-4semana" control={<Radio />} label="3 a 4 días de la semana " />
                            <FormControlLabel value="Ningun" control={<Radio />} label="Ningún día a la semana" />

                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            {/*------------ Ultimos--------------- */}
            <Grid item lg={12} sm={12} xs={12}>
                <InputLabel for="antnopaAuxiliarEscucharCaminar">Utiliza algún auxiliar para escuchar o caminar, ¿Desde hace cuánto y cuál?</InputLabel>

                <TextField
                    fullWidth
                    id="antnopaAuxiliarEscucharCaminar"
                    label="Auxiliar"
                    name="antNoPatologicos,antnopaAuxiliarEscucharCaminar"
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={antNoPatologicos.antnopaAuxiliarEscucharCaminar}
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <InputLabel for="antnopaAmikacinaGentamicina">¿Le han aplicado algunos de los siguientes medicamentos amikacina, gentamicina (frecuencia y período)?</InputLabel>
                <TextField
                    fullWidth
                    id="antnopaAmikacinaGentamicina"
                    label="Medicamento"
                    name="antNoPatologicos,antnopaAmikacinaGentamicina"
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={antNoPatologicos.antnopaAmikacinaGentamicina}
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <InputLabel for="antnopaSonidoFuerte">¿Ha estado expuesto a un sonido fuerte (frecuencia y período)?</InputLabel>
                <TextField
                    fullWidth
                    id="antnopaSonidoFuerte"
                    label="Sonido Fuerte"
                    name="antNoPatologicos,antnopaSonidoFuerte"
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={antNoPatologicos.antnopaSonidoFuerte}
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>


        </Grid>
    )
}

export default NoPatologicos