import { forwardRef, useEffect, useState } from 'react'

import {
    Radio,
    RadioGroup,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    FormGroup,
    InputLabel,
    Input,
    TextField,
    Checkbox,
    Typography
} from '@mui/material';
import useHistorialClinico from '../../../hooks/useHistorialClinico';


const NoPatologicos = () => {

    const { datos,datosMedico, handleChangeClinico, handleChangeClinicoMed, handleChangeServicios, dataAvance } = useHistorialClinico()
    const { antNoPatologicos } = dataAvance
    const [otVac, setOtVac] = useState(false);
    const [servicios, setServicios] = useState([])
    const [vacunas, setVacunas] = useState([])

    // useEffect(() => {
    //     console.log("No patologicos servicios vacunas")
    //     setServicios(datos.antNoPatologicos.antnopaServicios);
    //     setVacunas(datos.antNoPatologicos.antnopaVacunacion);
    // }, [datos])

    const handleServicios = (e) => {
        var arr = [];
        for (var i = 0; i < servicios.length; i++) {
            arr.push(servicios[i]);
        }
        if (arr.includes(e)) {
            arr.splice(arr.indexOf(e), 1);
        } else {
            arr.push(e)
        }
        setServicios(arr);
        handleChangeServicios(arr, "antnopaServicios");
    }

    const handleVacunas = (e) => {
        var arr = [];
        for (var i = 0; i < vacunas.length; i++) {
            arr.push(vacunas[i]);
        }
        if (arr.includes(e)) {
            arr.splice(arr.indexOf(e), 1);
        } else {
            arr.push(e)
        }
        setVacunas(arr);
        handleChangeServicios(arr, "antnopaVacunacion");
    }

    const checkboxList = (e) => {
        // console.log(e)
        var data = dataAvance.antNoPatologicos.antnopaServicios;
        if (data.includes(e))
            return <Checkbox checked />;
        else
            return <Checkbox />;

    }

    const checkboxListVac = (e) => {
        // console.log(e)
        var data = dataAvance.antNoPatologicos.antnopaVacunacion;
        if (data.includes(e))
            return <Checkbox checked />;
        else
            return <Checkbox />;

    }


    return (

        <Grid container spacing={2} p={1} style={{ backgroundColor:"#FFF" }}>
            {/*------------ Casa & material--------------- */}
            <Grid item lg={5} sm={5} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={2} sm={2} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Casa:</FormLabel>
                    </Grid>
                    <Grid lg={10} sm={10} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaCasaTipo"
                            disabled
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={dataAvance.antNoPatologicos.antnopaCasaTipo != "" ? antNoPatologicos.antnopaCasaTipo : "Propia"}
                        >
                            <FormControlLabel disabled value="Propia" control={<Radio />} label="Propia" />
                            <FormControlLabel disabled value="Rentada" control={<Radio />} label="Rentada" />
                            <FormControlLabel disabled value="Prestada" control={<Radio />} label="Prestada" />

                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={7} sm={7} xs={12}>
                <TextField
                    fullWidth
                    id="antnopaCasaMaterial"
                    label="Material de Construcción"
                    name="antNoPatologicos,antnopaCasaMaterial"
                    disabled
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={dataAvance.antNoPatologicos.antnopaCasaMaterial}
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>
            {/*------------ Cuartos & mascotas--------------- */}
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    fullWidth
                    id="antnopaCuartos"
                    label="Cuartos"
                    name="antNoPatologicos,antnopaCuartos"
                    disabled
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={dataAvance.antNoPatologicos.antnopaCuartos}
                    type='number'
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    fullWidth
                    id="antnopaPersonasCuarto"
                    label="Personas por cuarto"
                    name="antNoPatologicos,antnopaPersonasCuarto"
                    disabled
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={dataAvance.antNoPatologicos.antnopaPersonasCuarto}
                    type='number'
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    fullWidth
                    id="antnopaMascotas"
                    label="Mascotas"
                    name="antNoPatologicos,antnopaMascotas"
                    disabled
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={dataAvance.antNoPatologicos.antnopaMascotas}
                    // value={pHijos}
                    variant="standard"

                />
                <span id="Mascotas" style={{ fontSize: "0.6em", fontStyle: "italic" }}>Anotar tipo y cantidad.</span>

            </Grid>

            {/*------------ Servicios--------------- */}
            {servicios != undefined ?
                <>
                    <Grid lg={12} sm={12} xs={12} mt={2}>
                        <FormLabel id="checks" style={{ marginRight: "10px", width: "30%" }}>La casa cuenta con:</FormLabel>
                    </Grid>

                    <Grid lg={12} sm={12} xs={12} >
                        <FormGroup style={{ display: "contents" }} aria-labelledby="checks" disabled
                        // onChange={(e) => { handleServicios(e.target.value) }}
                        >
                            <FormControlLabel disabled style={{ width: "24%" }} value={"Agua entubada"} control={checkboxList("Agua entubada")} label="Agua entubada" />
                            <FormControlLabel disabled style={{ width: "24%" }} value={"Luz eléctrica"} control={checkboxList("Luz eléctrica")} label="Luz eléctrica" />
                            <FormControlLabel disabled style={{ width: "24%" }} value={"Gas (natural o lp)"} control={checkboxList("Gas (natural o lp)")} label="Gas (natural o LP)" />
                            <FormControlLabel disabled style={{ width: "24%" }} value={"Drenaje"} control={checkboxList("Drenaje")} label="Drenaje" />
                        </FormGroup>

                    </Grid>
                    <Grid lg={12} sm={12} xs={12} >
                        <FormGroup style={{ display: "contents" }} aria-labelledby="checks" disabled
                            onChange={(e) => { handleServicios(e.target.value) }}>
                            <FormControlLabel disabled style={{ width: "24%" }} value={"Agua no entubada"} control={checkboxList("Agua no entubada")} label="Agua no entubada" />
                            <FormControlLabel disabled style={{ width: "24%" }} value={"Leña o petróleo"} control={checkboxList("Leña o petróleo")} label="Leña o petróleo" />
                            <FormControlLabel disabled style={{ width: "24%" }} value={"Fosa séptica"} control={checkboxList("Fosa séptica")} label="Fosa séptica" />
                            <FormControlLabel disabled style={{ width: "24%" }} value={"Piso de tierra"} control={checkboxList("Piso de tierra")} label="Piso de tierra" />
                        </FormGroup>
                    </Grid></>
                : null}

            {/*------------ Higiene--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={2} sm={2} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Higiene personal (baño):</FormLabel>
                    </Grid>
                    <Grid lg={10} sm={10} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaHigienePersonal"
                            disabled
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={dataAvance.antNoPatologicos.antnopaHigienePersonal}
                        >
                            <FormControlLabel disabled style={{ width: "24%" }} value="Diario" control={<Radio />} label="Diario" />
                            <FormControlLabel disabled style={{ width: "24%" }} value="Cada 3er dia" control={<Radio />} label="Cada 3er dia" />
                            <FormControlLabel disabled style={{ width: "24%" }} value="Semanal" control={<Radio />} label="Semanal" />
                            <FormControlLabel disabled style={{ width: "24%" }} value="Mensual" control={<Radio />} label="Mensual" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            {/*------------ Aseo--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={2} sm={2} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Aseo bucal</FormLabel>
                    </Grid>
                    <Grid lg={10} sm={10} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaAceoBucal"
                            disabled
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={dataAvance.antNoPatologicos.antnopaAceoBucal}
                            defaultValue="Diario"
                        >
                            <FormControlLabel disabled style={{ width: "24%" }} value="3 x dia" control={<Radio />} label="3 x día" />
                            <FormControlLabel disabled style={{ width: "24%" }} value="1 o 2 x dia" control={<Radio />} label="1 o 2 x día" />
                            <FormControlLabel disabled style={{ width: "24%" }} value="Esporádico" control={<Radio />} label="Esporádico" />
                            <FormControlLabel disabled style={{ width: "24%" }} value="Nunca" control={<Radio />} label="Nunca" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            {/*------------ Vacunacion--------------- */}
            <Grid lg={12} sm={12} xs={12} mt={2}>
                <FormLabel id="checks" style={{ marginRight: "10px", width: "30%" }}>Vacunación:</FormLabel>
            </Grid>
            <Grid lg={12} sm={12} xs={12} style={{ textAlign: "center" }}>
                <FormGroup style={{ display: "contents" }} aria-labelledby="checks" disabled
                    // onChange={(e) => { handleVacunas(e.target.value); e.target.value === "Otras" ? setOtVac(!otVac) : null; }}
                    >
                    <FormControlLabel disabled style={{ width: "16%" }} value={"Tétanos"} control={checkboxListVac("Tétanos")} label="Tétanos" />
                    <FormControlLabel disabled style={{ width: "16%" }} value={"Sarampión/Rubeóla"} control={checkboxListVac("Sarampión/Rubeóla")} label="Sarampión/Rubeóla" />
                    <FormControlLabel disabled style={{ width: "16%" }} value={"Hepatitis"} control={checkboxListVac("Hepatitis")} label="Hepatitis B" />
                    <FormControlLabel disabled style={{ width: "16%" }} value={"Influenza"} control={checkboxListVac("Influenza")} label="Influenza" />
                    <FormControlLabel disabled style={{ width: "12%" }} value={"Otras"} control={checkboxListVac("Otras")} label="Otras" />
                    {otVac ? <TextField
                        fullWidth
                        id="medicoTitulo"
                        label="¿Cuáles?"
                        style={{ width: "20%" }}
                        name="medicoTitulo"
                        // value={pHijos}
                        variant="standard"

                    />
                        : null
                    }
                </FormGroup>
            </Grid>

            {/*------------ Ejercicio--------------- */}
            <Grid item lg={4} sm={4} xs={12} >
                <TextField
                    fullWidth
                    id="antnopaEjercicio"
                    label="Ejercicio"
                    name="antNoPatologicos,antnopaEjercicio"
                    disabled
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={dataAvance.antNoPatologicos.antnopaEjercicio}
                    // value={pHijos}
                    variant="standard"

                />
                <span id="Ejercicio" style={{ fontSize: "0.6em", fontStyle: "italic" }}>¿Cuál practica de manera regular?</span>
            </Grid>
            <Grid item lg={8} sm={8} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={2} sm={2} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Frecuencia</FormLabel>
                    </Grid>
                    <Grid lg={10} sm={10} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaDuracionActividad"
                            defaultValue={"Diario"}
                            // disabled
                            onChange={(e) => { handleChangeClinico(e) }}
                        // value={dataAvance.antNoPatologicos.antnopaDuracionActividad != "" ? antNoPatologicos.antnopaDuracionActividad : "Diario"}
                        >
                            <FormControlLabel disabled style={{ width: "32%" }} value="Diario" control={<Radio />} label="Diario" />
                            <FormControlLabel disabled style={{ width: "32%" }} value="2-3 veces x sem" control={<Radio />} label="2-3 veces x sem" />
                            <FormControlLabel disabled style={{ width: "32%" }} value="1 x semana" control={<Radio />} label="1 x semana" />
                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            {/*------------ Sangre--------------- */}
            <Grid item lg={4} sm={4} xs={12} pt={0}>
                <TextField
                    fullWidth
                    id="sanguíneo"
                    label="Tipo sanguíneo"
                    name="antNoPatologicos,antnopaTipoSangre"
                    disabled
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={dataAvance.antNoPatologicos.antnopaTipoSangre}
                    // value={pHijos}
                    variant="standard"

                />
                <span id="sanguíneo" style={{ fontSize: "0.6em", fontStyle: "italic" }}>Grupo y RH</span>
            </Grid>
            <Grid item lg={8} sm={8} xs={12} pt={0} style={{ backgroundColor:"#FFF" }}>
                <TextField
                    fullWidth
                    id="Comentarios"
                    label="Comentarios"
                    name="comentariosNoPatologicos,sec"
                    onChange={(e) => { handleChangeClinicoMed(e) }}
                    value={datosMedico.comentariosNoPatologicos}
                    // value={pHijos}
                    variant="standard"
                />
            </Grid>

            {/*------------ Intensidad Ej--------------- */}
            <Grid item lg={6} sm={6} xs={12}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={3} sm={3} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Intensidad del ejercicio:</FormLabel>
                    </Grid>
                    <Grid lg={9} sm={9} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaIntencidadEjercicio"
                            disabled
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={dataAvance.antNoPatologicos.antnopaIntencidadEjercicio}
                        >
                            <FormControlLabel disabled value="Leve" control={<Radio />} label="Leve (no sube la frecuencia cardíaca)" />
                            <FormControlLabel disabled value="Moderada" control={<Radio />} label="Moderada ( el ejercicio me permite hablar)" />
                            <FormControlLabel disabled value="Elevada" control={<Radio />} label="Elevada ( no puedo hablar mientras hago ejercicio)" />
                            <FormControlLabel disabled value="Deportista" control={<Radio />} label="Deportista de alto rendimiento" />


                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item lg={4} sm={4} xs={12}>
                <TextField
                    fullWidth
                    id="Duracion"
                    label="Duración de la actividad "
                    name="antNoPatologicos,antnopaDuracionActividad"
                    disabled
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={dataAvance.antNoPatologicos.antnopaDuracionActividad}
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>

            {/*------------ Hábitos alimenticios--------------- */}
            <Grid item lg={12} sm={12} xs={12}>
                <Typography component="h1" variant="h6" >
                    Hábitos alimenticios
                </Typography>
                <Typography variant="body1">
                    ¿Cuántos dias a la semana consume los siguientes alimentos?
                </Typography>
            </Grid>
            {/*------------ -----------------Habitos/Frutas & verduras--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={4} sm={4} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Frutas y verduras:</FormLabel>
                    </Grid>
                    <Grid lg={8} sm={8} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaFrutasVerduras"
                            disabled
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={dataAvance.antNoPatologicos.antnopaFrutasVerduras}
                        >
                            <FormControlLabel disabled value="5-7semana" control={<Radio />} label="5 a 7 días de la semana" />
                            <FormControlLabel disabled value="3-4semana" control={<Radio />} label="3 a 4 días de la semana " />
                            <FormControlLabel disabled value="Ningun" control={<Radio />} label="Ningún día a la semana" />

                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            {/*------------ -----------------Habitos/Azucares--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={4} sm={4} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Azúcares refinados (azúcar, refrescos, jugos, pan y galletas):</FormLabel>
                    </Grid>
                    <Grid lg={8} sm={8} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaAzucaresRefinados"
                            disabled
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={dataAvance.antNoPatologicos.antnopaAzucaresRefinados}
                        >
                            <FormControlLabel disabled value="5-7semana" control={<Radio />} label="5 a 7 días de la semana" />
                            <FormControlLabel disabled value="3-4semana" control={<Radio />} label="3 a 4 días de la semana " />
                            <FormControlLabel disabled value="Ningun" control={<Radio />} label="Ningún día a la semana" />

                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            {/*------------ -----------------Habitos/Agua--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={4} sm={4} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Consumo de 4 a 6 vasos de agua al día :</FormLabel>
                    </Grid>
                    <Grid lg={8} sm={8} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopa4a6vasosAgua"
                            disabled
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={dataAvance.antNoPatologicos.antnopa4a6vasosAgua}
                        >
                            <FormControlLabel disabled value="5-7semana" control={<Radio />} label="5 a 7 días de la semana" />
                            <FormControlLabel disabled value="3-4semana" control={<Radio />} label="3 a 4 días de la semana " />
                            <FormControlLabel disabled value="Ningun" control={<Radio />} label="Ningún día a la semana" />

                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>
            {/*------------ -----------------Habitos/Grasas--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={4} sm={4} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Consumo de grasas saturadas ( fritos, empanizados, capeados):</FormLabel>
                    </Grid>
                    <Grid lg={8} sm={8} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaGrasasSaturadas"
                            disabled
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={dataAvance.antNoPatologicos.antnopaGrasasSaturadas}
                        >
                            <FormControlLabel disabled value="5-7semana" control={<Radio />} label="5 a 7 días de la semana" />
                            <FormControlLabel disabled value="3-4semana" control={<Radio />} label="3 a 4 días de la semana " />
                            <FormControlLabel disabled value="Ningun" control={<Radio />} label="Ningún día a la semana" />

                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            {/*------------ -----------------Habitos/Carnes Rojas--------------- */}
            <Grid item lg={12} sm={12} xs={12} style={{ alignContent: "center" }}>
                <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center", width: "100%" }}>
                    <Grid lg={4} sm={4} xs={12}>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginRight: "10px", width: "30%" }}>Carnes rojas :</FormLabel>
                    </Grid>
                    <Grid lg={8} sm={8} xs={12}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="antNoPatologicos,antnopaCarnesRojas"
                            disabled
                            onChange={(e) => { handleChangeClinico(e) }}
                            value={dataAvance.antNoPatologicos.antnopaCarnesRojas}
                        >
                            <FormControlLabel disabled value="5-7semana" control={<Radio />} label="5 a 7 días de la semana" />
                            <FormControlLabel disabled value="3-4semana" control={<Radio />} label="3 a 4 días de la semana " />
                            <FormControlLabel disabled value="Ningun" control={<Radio />} label="Ningún día a la semana" />

                        </RadioGroup>
                    </Grid>
                </FormControl>
            </Grid>

            {/*------------ Ultimos--------------- */}
            <Grid item lg={12} sm={12} xs={12}>
                <InputLabel for="antnopaAuxiliarEscucharCaminar">Utiliza algún auxiliar para escuchar o caminar, ¿Desde hace cuánto y cuál?</InputLabel>

                <TextField
                    fullWidth
                    id="antnopaAuxiliarEscucharCaminar"
                    label="Auxiliar"
                    name="antNoPatologicos,antnopaAuxiliarEscucharCaminar"
                    disabled
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={dataAvance.antNoPatologicos.antnopaAuxiliarEscucharCaminar}
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <InputLabel for="antnopaAmikacinaGentamicina">¿Le han aplicado algunos de los siguientes medicamentos amikacina, gentamicina (frecuencia y período)?</InputLabel>
                <TextField
                    fullWidth
                    id="antnopaAmikacinaGentamicina"
                    label="Medicamento"
                    name="antNoPatologicos,antnopaAmikacinaGentamicina"
                    disabled
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={dataAvance.antNoPatologicos.antnopaAmikacinaGentamicina}
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <InputLabel for="antnopaSonidoFuerte">¿Ha estado expuesto a un sonido fuerte (frecuencia y período)?</InputLabel>
                <TextField
                    fullWidth
                    id="antnopaSonidoFuerte"
                    label="Sonido Fuerte"
                    name="antNoPatologicos,antnopaSonidoFuerte"
                    disabled
                    onChange={(e) => { handleChangeClinico(e) }}
                    value={dataAvance.antNoPatologicos.antnopaSonidoFuerte}
                    // value={pHijos}
                    variant="standard"

                />
            </Grid>


        </Grid>
    )
}

export default NoPatologicos