import { createContext, useState, useEffect } from "react";
import axios from 'axios'
import  secureLocalStorage  from  "react-secure-storage";
const RegistroUsuarioContext = createContext()

const client = axios.create({
    baseURL: "https://appsexpedientemedico.azurewebsites.net/api"
  });

const RegistroUsuarioProvider = ({ children }) => {

    const [usuario, setUsuario] = useState({
        userNombre: '',
        userPApellido: '',
        userSApellido: '',
        userEmail: '',
        userPass: '',
        userPhone: '',
        userGenderId: '2',
        userAge: '0',
        userCpId: '',
        userStreet: '',
        userNext: '',
        userNint: '',
        userCtId: '',
        userCdatos: 1,
        Municipio: '',
        Colonia: '',
        userCodeReset: '',
        userNoEmpleado: "",
        userNacimiento:""
    })

    const [datos, setDatos] = useState({
        coGuid: '',
        stateGuid: '',
        municipio: '',
        cp: '',
        aceptCdatos: true
    })
    const { coGuid, cp, stateGuid } = datos
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([])
    const [cps, setCps] = useState([])
    const [centrosTrabajo, setCentrosTrabajo] = useState([])
    const [error, setError] = useState('')


    const handleChangeUsuario = (event) => {
        setUsuario({
            ...usuario,
            [event.target.name]: event.target.name === 'telefono' ? event.target.value.toString().replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '') : event.target.value,
        });
    };
    const handleChangeUsuario2 = (name, value) => {
        setDatos({
            ...datos,
            [name]: value
        });
    };
    const handleChangeUsuario3 = (name, value) => {
        setUsuario({
            ...usuario,
            [name]: value
        });
    };

    const limpiarPaises = () => {
        setStates([])
        setCps([])
        setDatos({
            coGuid: '',
            stateGuid: '',
            municipio: '',
            cp: ''
        })
    }

    const limpiarEstados = () => {
        setCps([])
        setDatos({
            ...datos,
            stateGuid: '',
            municipio: '',
            cp: ''
        })
    }

    const countryApi = async () => {
        try {
            const response = await client.get('/countriess', {
                headers: {

                    'Access-Control-Allow-Origin': '*',

                    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',

                    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',

                    'Content-Type': 'application/json',
                }
            })
            
            setCountries(response.data.response)
        } catch (e) {
        }
    }

    const statesApi = async (id) => {
        try {
            if (id !== '') {
                const response = await client.get(`/states?guid=${id}`, {
                    headers: {

                        'Access-Control-Allow-Origin': '*',

                        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',

                        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',

                        'Content-Type': 'application/json',
                    }
                })
                setStates(response.data.response)
                setDatos({
                    ...datos,
                    stateGuid: response.data.response[0].stateGuid
                });
            }
        } catch (e) {

        }
    }

    const cpApi = async (guid, cp) => {
        if (guid !== null && guid !== '' && guid !== undefined) {
            setError('')
            if (cp !== null && cp !== '' && cp !== undefined) {
                setError('')
                try {

                    const response = await client.get(`/cp?stateguid=${guid}&cp=${cp}`, {
                        headers: {

                            'Access-Control-Allow-Origin': '*',

                            'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',

                            'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',

                            'Content-Type': 'application/json',
                        }
                    })
                    if (response.data.response.length < 1) {
                        setError('No se ha encontrado el código postal')
                    } else {
                        setError('')
                        setCps(response.data.response)
                        setDatos({
                            ...datos,
                            municipio: response.data.response[0]['cpMunicipio']
                        });
                    }

                } catch (e) {

                }
            } else {
                setError('Debes ingresar un código postal')
            }
        } else {
            setError('Debes seleccionar un estado')
        }
    }
    const centrosTrabajoApi = async (guid) => {
        try {
            console.log(guid);
            const response = await client.get(`/centrodetrabajo/getByCouintry2?countryGuid=${guid}`, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
                    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                    'Content-Type': 'application/json',
                }
            })

            setCentrosTrabajo(response.data.response)
            setUsuario({
                ...usuario,
                userCtId: response.data.response[0]['ctId']
            });

        } catch (e) {
            setError(`No hay centros de trabajo que pertenezcan a `)
        }
    }

    const createUser = async () => {
        console.log(usuario);
        // eslint-disable-next-line
        // const json = {
        //     "userId": 0,//DEJALO POR DEFAULT
        //     "userGuid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",//DEJALO POR DEFAULT
        //     "userRolId": 0,//DEJALO POR DEFAULT
        //     "userNombre": usuario.userNombre,
        //     "userPApellido": usuario.userPApellido,
        //     "userSApellido": usuario.userSApellido,
        //     "userEmail": usuario.userEmail,
        //     "userPhone": usuario.userPhone,
        //     "userGenderId": usuario.userGenderId,
        //     "userAge": usuario.userAge,
        //     "userCtId": usuario.userCtId,
        //     "userPass": "",
        //     "userStreet": usuario.userStreet,
        //     "userNext": usuario.userNext,
        //     "userNint": usuario.userNint,
        //     "userCpId": usuario.userCpId,
        //     "userCdatos": 1,//DEJALO POR DEFAULT
        //     "userDcreate": "2023-07-11T06:41:00.560Z",//DEJALO POR DEFAULT
        //     "userStatus": 0,//DEJALO POR DEFAULT
        //     "userTokenActivation": "3fa85f64-5717-4562-b3fc-2c963f66afa6",//DEJALO POR DEFAULT
        //     "userExpToken": "2023-07-11T06:41:00.560Z",//DEJALO POR DEFAULT
        //     "userNoEmpleado": usuario.userNoEmpleado,
        //     "Municipio": coloniaSelect.cpMunicipio,
        //     "Colonia": coloniaSelect.cpColonia,
        //     "userCodeReset": "" //DEJALO POR DEFAULT
        // }

        const response = await client.post('/users/create', usuario, {
            headers: {
                Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}`,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'Content-Type': 'application/json',
            }
        });
    }
    //centrosTrabajoApi(stateGuid)
    useEffect(() => {
        //centrosTrabajoApi(stateGuid)
        // eslint-disable-next-line
    }, [stateGuid])

    useEffect(() => {
        console.log(coGuid);
        console.log(coGuid !== '' && coGuid !== null && coGuid !== undefined);
        if(coGuid !== '' && coGuid !== null && coGuid !== undefined){
            centrosTrabajoApi(coGuid)
        }else{
            setCentrosTrabajo([])
        }
        statesApi(coGuid)
        
        // eslint-disable-next-line
    }, [coGuid])

    useEffect(() => {
        if (cp === '') {
            setCps([])
            setDatos({
                ...datos,
                municipio: ''
            })
        }
        // eslint-disable-next-line
    }, [cp])

    return (
        <RegistroUsuarioContext.Provider
            value={{
                usuario,
                setUsuario,
                handleChangeUsuario,
                handleChangeUsuario2,
                handleChangeUsuario3,
                countryApi,
                countries,
                states,
                datos,
                error,
                setError,
                cpApi,
                cps,
                limpiarPaises,
                limpiarEstados,
                createUser,
                centrosTrabajoApi,
                centrosTrabajo
            }}
        >
            {children}
        </RegistroUsuarioContext.Provider>
    )
}

export {
    RegistroUsuarioProvider
}

export default RegistroUsuarioContext