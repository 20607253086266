import axios from "axios";
import { createContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import useAuth from "../hooks/useAuth";
import  secureLocalStorage  from  "react-secure-storage";
const AdminContext = createContext()

const client = axios.create({
    baseURL: "https://appsexpedientemedico.azurewebsites.net/api"
});

const AdminProvider = ({ children }) => {
    const navigate = useNavigate()

    const { validarToken } = useAuth()

    const [error, setError] = useState({
        band: false,
        texto: ''
    })
    const [horario, setHorarios] = useState({
        horarioInicial: '',
        horarioFinal: ''
    })
    const [bandHorarios, setBandHorarios] = useState(false)

    const [ datosFiltros, setDatosFiltros ] = useState({
        userNombre: '',
        userPApellido: '',
        userSApellido: '',
        userEmail: '',
        userRolDesc: ''
    })

    const [loadTime, setLoadTime] = useState(false)
    const [ usuarios, setUsuarios] = useState([])
    const [ usuariosFiltro, setUsuariosFiltro] = useState([])
    const [roles, setRoles] = useState([])

    const handleChange = e => {
        setDatosFiltros({
            ...datosFiltros,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeHorario = e => {
        setHorarios({
            ...horario,
            [e.target.name]: e.target.value
        })
    }

    const getHorarios = async() => {
        try{
            setLoadTime(true)
            const config = {
                headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}`}
            };
            const response = await client.get('/admin/getHorario', config)
            setHorarios({
                horarioInicial: response.data.response.horaioInicial,
                horarioFinal: response.data.response.horaFinal
            });
            setLoadTime(false)
        }catch(e){

        }
    }

    const getUsuarios = async () => {
        try{
            validarToken()
            setLoadTime(true)
            let tokens = secureLocalStorage.getItem('tokenid'); 
            const config = {
                headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}`}
            };  
            const response = await client.post(`/admin/getUsers`,``, config)

            const response2 = await client.post(`/rol/getroles`,``, config)
            setRoles(response2.data.response)

            /*const array = response.data.response.map(e => ( {
                ...e,
                userRolDesc: response2.data.response.find(i => i.rolId == e.userRolId).rolDesc
            }))*/
            setUsuarios(response.data.response);
            setUsuariosFiltro(response.data.response);      
            setLoadTime(false)
        }catch(e){
            setLoadTime(false)
        }
    }

    const filtrar = () => {
        const { userNombre, userPApellido, userSApellido, userEmail, userRolDesc } = datosFiltros

        const filtrarArray = usuarios.filter(e => 
            e.userNombre.toLowerCase().startsWith(userNombre.toLowerCase())
            && e.userPApellido.toLowerCase().startsWith(userPApellido.toLowerCase())
            && e.userSApellido.toLowerCase().startsWith(userSApellido.toLowerCase())
            && e.userEmail.toLowerCase().startsWith(userEmail.toLowerCase())
            && e.rolName.toLowerCase().startsWith(userRolDesc.toLowerCase())
        )
        setUsuariosFiltro(filtrarArray)
    }

    useEffect(() => {
        getUsuarios()
    }, [])

    useEffect(() => {
        filtrar()
    }, [datosFiltros])

    const actualizarHorario = async () => {
        try{
            setLoadTime(true)
            const config = {
                headers: { Authorization: `Bearer ${secureLocalStorage.getItem('tokenid')}`}
            };
            const data ={
                "hi": horario.horarioInicial,
                "hf": horario.horarioFinal
            }
            const response2 = await client.post(`/admin/setHorario`,data, config)
            const response = await client.get('/admin/getHorario', config)
            setHorarios({
                horarioInicial: response.data.response.horaioInicial,
                horarioFinal: response.data.response.horaFinal
            });
            setBandHorarios(false)
            setLoadTime(false)
        }catch(e){

        }
    }

    return (
        <AdminContext.Provider
            value={{
                setError,
                error,
                loadTime,
                getUsuarios,
                usuarios,
                usuariosFiltro,
                datosFiltros,
                handleChange,
                roles,
                bandHorarios,
                setBandHorarios,
                getHorarios,
                horario,
                handleChangeHorario,
                actualizarHorario
            }}
        >
            {children}
        </AdminContext.Provider>
    )
}

export {
    AdminProvider
}

export default AdminContext