import { forwardRef, useEffect, useState } from 'react'

import {
    Grid,
    TextField,
    Checkbox,
    FormControlLabel,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import useHistorialClinico from '../../../hooks/useHistorialClinico';


const FirmaMedico = () => {

    const { datosMedico,
        dataUser,
        handleChangeClinicoMed,
        laborales,
        setLaborales,
        antecedentesLaborales,
        setAntecedentesLaborales,
        handleChangeFirmaM} = useHistorialClinico()
    const { historialclinico } = datosMedico;

    const Check = (e) =>{
        // console.log(datosMedico)
        if(e === "Si"){
            return <Checkbox checked />;
        }else{
            return <Checkbox onChange={(e) => { handleChangeFirmaM(e) }}/>;
        }
    }

    return (
        <>
            <Grid container spacing={2} p={1} style={{ backgroundColor:"#FFF" }}>
                <Grid item lg={4} sm={4} xs={12} mdOffset={4}>
                </Grid>
                <Grid item lg={4} sm={4} xs={12} mdOffset={4}>
                    <FormControlLabel  required control={Check(datosMedico.historialclinico.hcFirmaMedico)} label="Confirmación por parte del Médico" />
                </Grid>

            </Grid>
        </>
    )
}

export default FirmaMedico