import { useEffect } from 'react';

import { ContrasenaProvider } from '../../context/ContrasenaProvider'
import useAuth from '../../hooks/useAuth'
import Navbar from '../Navbar';
import Alerta from './Alerta';
import Alerta2 from './Alerta2';
import Formulario from './Formulario';
import Load from './Load';


export default function Contrasena() {

    const { setVentana } = useAuth()

    useEffect(() => {
        setVentana('/login')
    }, [])
    return (
        <div>
            <ContrasenaProvider>
                <Load />
                <Alerta />
                <Alerta2 />
                <Navbar />
                <Formulario />
            </ContrasenaProvider>
        </div>
    )
}