import { forwardRef, useEffect, useState } from 'react'

import {
    Radio,
    RadioGroup,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    InputLabel,
    Input,
    TextField,
    Typography,
    Button
} from '@mui/material';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import useHistorialClinico from '../../../hooks/useHistorialClinico';


const ExploracionFisica = () => {

    const { datosMedico, dataUser, handleChangeClinicoMed } = useHistorialClinico()
    const { exploracionFisica } = datosMedico;


    return (
        <>
            <Grid container spacing={2} p={1} style={{ backgroundColor:"#FFF" }}>
                <Grid item lg={2} sm={2} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisPeso"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisPeso}
                        fullWidth
                        id="expFisPeso"
                        // defaultValue={historialclinico.}
                        label="Peso"
                        autoFocus
                        variant="standard"
                    />
                </Grid>

                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisTalla"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisTalla}
                        fullWidth
                        id="expFisTalla"
                        // defaultValue={historialclinico.}
                        label="Talla"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisTa"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisTa}
                        fullWidth
                        id="expFisTa"
                        // defaultValue={historialclinico.}
                        label="TA"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisLpm"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisLpm}
                        fullWidth
                        id="expFisLpm"
                        // defaultValue={historialclinico.}
                        label="LPM"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisRmp"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisRmp}
                        fullWidth
                        id="expFisRmp"
                        // defaultValue={historialclinico.}
                        label="RPM"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisTemp"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisTemp}
                        fullWidth
                        id="expFisTemp"
                        // defaultValue={historialclinico.}
                        label="TEMP"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisSato2"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisSato2}
                        fullWidth
                        id="expFisSato2"
                        // defaultValue={historialclinico.}
                        label="Sat O2"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={2} sm={2} xs={12}>
                    Agudeza visual Snellen:
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisAgudVisSnellenOd"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisAgudVisSnellenOd}
                        fullWidth
                        id="expFisAgudVisSnellenOd"
                        // defaultValue={historialclinico.}
                        label="OD"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisAgudVisSnellenOi"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisAgudVisSnellenOi}
                        fullWidth
                        id="expFisAgudVisSnellenOi"
                        // defaultValue={historialclinico.}
                        label="OI"
                        autoFocus
                        variant="standard"
                    />
                </Grid>

                {/* SEGUNDA FILA */}
                <Grid item lg={1} sm={1} xs={12}>
                    Agudeza visual Jaeger:
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisAgudVisJeagerOd"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisAgudVisJeagerOd}
                        fullWidth
                        id="expFisAgudVisJeagerOd"
                        // defaultValue={historialclinico.}
                        label="OD"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisAgudVisJeagerOi"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisAgudVisJeagerOi}
                        fullWidth
                        id="expFisAgudVisJeagerOi"
                        // defaultValue={historialclinico.}
                        label="OI"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisDiscrimColores"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisDiscrimColores}
                        fullWidth
                        id="expFisDiscrimColores"
                        // defaultValue={historialclinico.}
                        label="Discriminación Colores (Ishihara)"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    Campos visuales:
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisCampVisOd"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisCampVisOd}
                        fullWidth
                        id="expFisCampVisOd"
                        // defaultValue={historialclinico.}
                        label="OD"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisCampVisOi"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisCampVisOi}
                        fullWidth
                        id="expFisCampVisOi"
                        // defaultValue={historialclinico.}
                        label="OI"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={1} sm={1} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisIms"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisIms}
                        fullWidth
                        id="expFisIms"
                        // defaultValue={historialclinico.}
                        label="IMC"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={2} sm={2} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisPorGrasa"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisPorGrasa}
                        fullWidth
                        id="expFisPorGrasa"
                        // defaultValue={historialclinico.}
                        label="% Grasa"
                        autoFocus
                        variant="standard"
                    />
                </Grid>

                {/* TERCERA FILA */}
                <Grid item lg={2} sm={2} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisPoMusculo"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisPoMusculo}
                        fullWidth
                        id="expFisPoMusculo"
                        // defaultValue={historialclinico.}
                        label="% Musculo"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={2} sm={2} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisPorVisceral"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisPorVisceral}
                        fullWidth
                        id="expFisPorVisceral"
                        // defaultValue={historialclinico.}
                        label="% Visceral"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={2} sm={2} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisGlucosa"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisGlucosa}
                        fullWidth
                        id="expFisGlucosa"
                        // defaultValue={historialclinico.}
                        label="Glucosa"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={2} sm={2} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisColesterol"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisColesterol}
                        fullWidth
                        id="expFisColesterol"
                        // defaultValue={historialclinico.}
                        label="Colesterol"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={2} sm={2} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisTrigliceridos"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisTrigliceridos}
                        fullWidth
                        id="expFisTrigliceridos"
                        // defaultValue={historialclinico.}
                        label="Triglicéridos"
                        autoFocus
                        variant="standard"
                    />
                </Grid>

                {/* Resto */}
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisHabitExterior"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisHabitExterior}
                        fullWidth
                        id="expFisHabitExterior"
                        // defaultValue={historialclinico.}
                        label="Habitus Exterior"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisCraneo"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisCraneo}
                        fullWidth
                        id="expFisCraneo"
                        // defaultValue={historialclinico.}
                        label="Cráneo"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisOidos"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisOidos}
                        fullWidth
                        id="expFisOidos"
                        // defaultValue={historialclinico.}
                        label="Oídos"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisOjos"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisOjos}
                        fullWidth
                        id="expFisOjos"
                        // defaultValue={historialclinico.}
                        label="Ojos"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisNariz"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisNariz}
                        fullWidth
                        id="expFisNariz"
                        // defaultValue={historialclinico.}
                        label="Nariz"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisBoca"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisBoca}
                        fullWidth
                        id="expFisBoca"
                        // defaultValue={historialclinico.}
                        label="Boca"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisFaringe"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisFaringe}
                        fullWidth
                        id="expFisFaringe"
                        // defaultValue={historialclinico.}
                        label="Faringe"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisCuello"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisCuello}
                        fullWidth
                        id="expFisCuello"
                        // defaultValue={historialclinico.}
                        label="Cuello"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisTorax"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisTorax}
                        fullWidth
                        id="expFisTorax"
                        // defaultValue={historialclinico.}
                        label="Tórax"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisAbdomen"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisAbdomen}
                        fullWidth
                        id="expFisAbdomen"
                        // defaultValue={historialclinico.}
                        label="Abdomen"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisColumnaVert"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisColumnaVert}
                        fullWidth
                        id="expFisColumnaVert"
                        // defaultValue={historialclinico.}
                        label="Columna vertebral"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
               
                <Grid item lg={12} sm={12} xs={12}>
                    <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center" }}>
                        <FormLabel id="marcha" style={{ marginRight: "10px" }}>Alt. marcha / punta y talón</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="exploracionFisica,expFisAltMarchaPuntaTalon"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={exploracionFisica.expFisAltMarchaPuntaTalon}
                            defaultValue="No"
                            disabled
                        >
                            <FormControlLabel value={"Si"} control={<Radio />} label="Si" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisMiembrosSup"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisMiembrosSup}
                        fullWidth
                        id="expFisMiembrosSup"
                        // defaultValue={historialclinico.}
                        label="Miembros Superiores"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisMiembrosInf"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisMiembrosInf}
                        fullWidth
                        id="expFisMiembrosInf"
                        // defaultValue={historialclinico.}
                        label="Miembros Inferiores"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisPiel"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisPiel}
                        fullWidth
                        id="expFisPiel"
                        // defaultValue={historialclinico.}
                        label="Piel"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisArcosMovi"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisArcosMovi}
                        fullWidth
                        id="expFisArcosMovi"
                        // defaultValue={historialclinico.}
                        label="Arcos de movilidad"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisPruebasVesti"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisPruebasVesti}
                        fullWidth
                        id="expFisPruebasVesti"
                        // defaultValue={historialclinico.}
                        label="Pruebas Vestibulares"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisReflejosOsteo"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisReflejosOsteo}
                        fullWidth
                        id="expFisReflejosOsteo"
                        // defaultValue={historialclinico.}
                        label="Reflejos osteotendinosos"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <FormControl style={{ display: "-webkit-inline-box", WebKitBoxAlign: "center" }}>
                        <FormLabel id="marcha" style={{ marginRight: "10px" }}>Tatuajes o seña particular</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="exploracionFisica,expFisTatuajes"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={exploracionFisica.expFisTatuajes}
                            defaultValue="No"
                            disabled
                        >
                            <FormControlLabel value={"Si"} control={<Radio />} label="Si" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormControl style={{ WebKitBoxAlign: "center" }}>
                        <FormLabel id="marcha" style={{ marginRight: "10px" }}>Exámenes especiales (anotar los que requiera según el puesto)</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="exploracionFisica,expFisAudiometria"
                            onChange={(e) => { handleChangeClinicoMed(e) }}
                            value={exploracionFisica.expFisAudiometria}
                            defaultValue="Audiometria"
                            disabled
                        >
                            <FormControlLabel value={"Audiometria"} control={<Radio />} label="*Audiometría" />
                            <FormControlLabel value={"Espirometria"} control={<Radio />} label="*EspirometrÍa" />
                            <FormControlLabel value={"Rayos X"} control={<Radio />} label="Rayos X" />
                            <FormControlLabel value={"Estudios laboratorio"} control={<Radio />} label="Estudios laboratorio" />
                            <FormControlLabel value={"Especificar"} control={<Radio />} label="Especificar" />


                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    Si cuenta con estudio y diagnóstico previo 
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisDiagPrevInstEmi"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisDiagPrevInstEmi}
                        fullWidth
                        id="expFisDiagPrevInstEmi"
                        // defaultValue={historialclinico.}
                        label="Institución emisora"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisDiagPrevFecha"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisDiagPrevFecha}
                        fullWidth
                        id="expFisDiagPrevFecha"
                        // defaultValue={historialclinico.}
                        label="Fecha"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisDiagPrevDiagnostico"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisDiagPrevDiagnostico}
                        fullWidth
                        id="expFisDiagPrevDiagnostico"
                        // defaultValue={historialclinico.}
                        label="Diagnóstico"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisDiagPrevTipoEst"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisDiagPrevTipoEst}
                        fullWidth
                        id="expFisDiagPrevTipoEst"
                        // defaultValue={historialclinico.}
                        label="Tipo de estudio"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisDiagPrevDiagEstudio"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisDiagPrevDiagEstudio}
                        fullWidth
                        id="expFisDiagPrevDiagEstudio"
                        // defaultValue={historialclinico.}
                        label="Diagnóstico del estudio"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisDiagPrevFechaEstudio"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisDiagPrevFechaEstudio}
                        fullWidth
                        id="expFisDiagPrevFechaEstudio"
                        // defaultValue={historialclinico.}
                        label="Fecha del estudio"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisDiagPrevComentarios"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisDiagPrevComentarios}
                        fullWidth
                        id="expFisDiagPrevComentarios"
                        // defaultValue={historialclinico.}
                        label=" Comentarios de resultados"
                        autoFocus
                        variant="standard"
                    />
                   
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisIdx"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisIdx}
                        fullWidth
                        id="expFisIdx"
                        // defaultValue={historialclinico.}
                        label="Idx."
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisPlan"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisPlan}
                        fullWidth
                        id="expFisPlan"
                        // defaultValue={historialclinico.}
                        label="Plan"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisRecomendable"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisRecomendable}
                        fullWidth
                        id="expFisRecomendable"
                        // defaultValue={historialclinico.}
                        label="Recomendable"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={9} sm={9} xs={12}>
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisRecomendableCondi"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisRecomendableCondi}
                        fullWidth
                        id="expFisRecomendableCondi"
                        // defaultValue={historialclinico.}
                        label="Recomendable Condicionado"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={9} sm={9} xs={12}>
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                    <TextField
                        autoComplete="given-name"
                        name="exploracionFisica,expFisNoRecomendable"
                        onChange={(e) => { handleChangeClinicoMed(e) }}
                        value={exploracionFisica.expFisNoRecomendable}
                        fullWidth
                        id="expFisNoRecomendable"
                        // defaultValue={historialclinico.}
                        label="No Recomendable"
                        autoFocus
                        variant="standard"
                    />
                </Grid>
                
            </Grid>
        </>
    )
}

export default ExploracionFisica