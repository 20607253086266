import { forwardRef, useEffect, useState } from 'react'

import {
    Grid,
    TextField,
    Checkbox,
    FormControlLabel,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import useHistorialClinico from '../../../hooks/useHistorialClinico';
import { Typography } from 'antd';


const FirmaPaciente = () => {

    const { datosMedico,
        dataUser,
        handleChangeClinicoMed,
        laborales,
        setLaborales,
        antecedentesLaborales,
        setAntecedentesLaborales,
        handleChangeFirmaM,
        firmaPaciente,
        setFirmaPaciente} = useHistorialClinico()
    const { historialclinico } = datosMedico;

    useEffect(() =>{
        // console.log(firmaPaciente.firma)
    },[firmaPaciente])

    const handleChangeCheck = (e)=>{
        if (firmaPaciente.firma === "Si") {
            setFirmaPaciente({firma:"No"})
        }else{
            setFirmaPaciente({firma:"Si"})
        }
    }

    const Check = (e) => {
        // console.log(datosMedico)
        if (e.firma === "Si") {
            return <Checkbox checked />;
        } else {
            return <Checkbox/>;
        }
    }

    return (
        <>
            <Grid container spacing={2} p={1} style={{ backgroundColor:"#FFF" }}>
                <Grid item lg={4} sm={4} xs={12} mdOffset={4}>
                </Grid>
                <Grid item lg={4} sm={4} xs={12} mdOffset={4} style={{textAlign:"center"}}>
                    <FormControlLabel onChange={(e) =>{handleChangeCheck(e)}} required control={Check(firmaPaciente)} label="Confirmación del paciente" />
                </Grid>
                <Grid item lg={4} sm={4} xs={12} mdOffset={4}>
                </Grid>
                <Grid item lg={3} sm={3} xs={12} mdOffset={4}>
                </Grid>
                <Grid item lg={6} sm={6} xs={12} mdOffset={4}>
                    <Typography variant="body2" style={{textAlign:"justify"}}>Hago constar que no oculto enfermedad alguna, ni padezco afección diagnosticable únicamente por datos recogidos en el interrogatorio, por lo tanto asumo la responsabilidad de lo asentado.</Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default FirmaPaciente