
//importar librerias
import { useState, useEffect } from 'react';
import { Table, Button, Tooltip, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TablePagination from '@mui/material/TablePagination';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router-dom';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import useHistorialClinico from '../../../hooks/useHistorialClinico';
import SearchIcon from '@mui/icons-material/Search';
import useAuth from '../../../hooks/useAuth';

const TablaListaAnios = () => {
    const history = useNavigate()

    const { setVentana } = useAuth()
    const { historialPaciente, usuariosListos, userExam, obtenerHistorialPaciente, obtenerHistorialAnio } = useHistorialClinico();
    const columns = [
        { id: 'Anio', label: 'Año' },
        { id: 'Tipo', label: 'Tipo' },
        { id: 'Detalles', label: 'Detalles' },
    ];
    // eslint-disable-next-line

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const getExamen = (e) => {
        var data = {
            "userGuid": JSON.parse(localStorage.getItem('guidBusquedaExamenes')).userGuid, //guid del usuario
            "year": e.hcYear//anio del historial
        }
        obtenerHistorialAnio(data)
    }

    const getListaExamenes = (e) => {
        console.log("Consultando avance")
        console.log(JSON.parse(localStorage.getItem('guidBusquedaExamenes')).userGuid)
        obtenerHistorialPaciente()
    }

    useEffect(() => {
        getListaExamenes();
    }, [])

    // useEffect(() => {
    //     console.log(historialPaciente);
    // }, [historialPaciente])
    return (
        <>
        
            {historialPaciente != undefined && historialPaciente != "No hay historiales terminados para este usuario" ?
                <Paper>
                    {console.log(historialPaciente)}
                    <TableContainer >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align='center'
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {historialPaciente
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow
                                                key={row.hcGuid}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">{row.hcYear}</TableCell>
                                                <TableCell align="center">{row.hcTipo}</TableCell>
                                                <TableCell align='center' style={{ display: "grid" }}>
                                                    <Tooltip title="Detalles">
                                                        <Button onClick={() => getExamen(row)}>
                                                            <SearchIcon />Ver Detalle
                                                        </Button>
                                                    </Tooltip>

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={historialPaciente.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper> :
                <Paper>
                    <Typography>
                        {historialPaciente}
                    </Typography>
                </Paper>
            }
        </>
    )
}

export default TablaListaAnios